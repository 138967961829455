import {
	Col,
	Row,
	Container,
	Card,
	Fade,
	Nav,
	Offcanvas
} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { NavBarPrincipal, MenuMobile, MenuDesk } from "./styles";
import Logo from "../../images/img/logo.svg";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLoginReset } from "../../slices/loginSlice";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api.js";
import { useState, useEffect } from "react";
import images from "../../images/images";

export function NavBar({ user }) {
	// let navigate = useNavigate();
	const token = sessionStorage.getItem("token");
	const [modalErro, setModalErro] = useState(false);
	const [modalMsg, setModalMsg] = useState("");
	const [campanhas, setCampanhas] = useState()
	// const mpv = localStorage.getItem("hasmpv");


	let dispatch = useDispatch();
	const Logout = () => {
		dispatch(getLoginReset());
		sessionStorage.removeItem("token");
		sessionStorage.removeItem("UserInfo");
		sessionStorage.removeItem("UserInfoNoValidation");
		window.location.href = '/'
	};

	useEffect(() => {
		obterCampanhasDisponiveis()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])



	const obterCampanhasDisponiveis = () => {
		api
			.get(`${rotasApi.campanhasDisponiveis}`)
			.then((result) => {
				// console.log('result aqui', result.data)
				setCampanhas(result.data.campanhas)
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const abreAbaPremios = () => {
		if (sessionStorage.getItem("acessoEspelho") === 'true') {
			setModalErro(true);
			setModalMsg('Error, Catálogo de premios não pode ser acessado do acesso espelho.');
			setTimeout(() => {
				setModalErro(false);
			}, 3000);
		} else {
			api
				.get(rotasApi.linkCatalogoPremios)
				.then(result => {
					const { mensagem, url, sucesso } = result.data;
					if (sucesso) {
						window.open(`${url}`, "_blank");
						return;
					}
					setModalErro(true);
					setModalMsg(mensagem);
					setTimeout(() => {
						setModalErro(false);
					}, 3000);
				})
				.catch(error => {
					console.log(error);
				});
			// setModalErro(true);
			// setModalMsg('Catálogo de prêmios está temporariamente fora do ar devido a manutenção.');
			// setTimeout(() => {
			// 	setModalErro(false);
			// }, 3000);
		}
	};
	const RemoveRedirectCampanha = () => {
		localStorage.removeItem("redirect");
	}

	let cor = "#9CCB3D";

	return (
		<NavBarPrincipal>
			{modalErro && (
				<Row className="modalMsgErro">
					<Col className="d-flex justify-content-center">
						<Fade in={modalErro}>
							<Card
								className="text-center p-3 m-2 z-index-top position-absolute"
								bg="danger"
								text="white"
							>
								<Card.Body>
									<Card.Title as="h2">Erro</Card.Title>
									<Card.Text>{modalMsg}</Card.Text>
								</Card.Body>
							</Card>
						</Fade>
					</Col>
				</Row>
			)}

			{user.cargo.descricao === "GESTOR DA INFORMACAO" && (
				<>
					<MenuDesk style={{ zIndex: "1" }}>
						{/* <img src={images.NavBackgroundNatal} className="bg-nav-tema" alt=""></img> */}
						{/* <img src={images.BackgroundNavBf} className="bg-nav-tema" alt=""></img> */}
						<Container className="p-0 gestor">
							<Row className="p-0 m-0">
								<Col className="p-0 m-0">
									<Navbar className="items-container">
										<Link className="col-2 navbar-brand" to="/home">
											<img className="logo-nav-bar" src={Logo} alt="" />
										</Link>
										<div className="col-1"></div>
										<Nav
											className="col-9 d-flex justify-content-around navbar-itens"
										>
											<NavLink className="nav-link" to="/home" onClick={RemoveRedirectCampanha()} >
												Home
											</NavLink>
											<NavLink className="nav-link" to="/sobre-maistop">
												Sobre o +top
											</NavLink>
											<NavLink className="nav-link" to="/planilhas" >
												Publicar Planilhas
											</NavLink>
											<NavLink className="nav-link" to="/campanhas">
												Campanhas
												{campanhas > 0 ? <div className="notificacao-icon"><p>{campanhas}</p></div> : ('')}
											</NavLink>
											<Link className="nav-link" to={"https://saibamais.data2.app/?t=" + token} target="_blank">Treinamentos</Link>
											<NavLink className="nav-link" to="/minha-revenda">Minha Revenda</NavLink>
											<NavLink className="nav-link" to="/extrato">Extrato</NavLink>
											<NavLink className="nav-link" to="/cadastro-loja" >Cadastro de Lojas</NavLink>
											{/* {sessionStorage.getItem("acessoEspelho") === 'true' ? ('') : <Nav.Link href="#" onClick={abreAbaPremios}>
												Catálogo
											</Nav.Link>} */}
											<NavLink className="nav-link perfil" to="/meu-perfil">
												Meu perfil
											</NavLink>
											<NavLink className="nav-link" to="/" onClick={Logout}>
												Sair
											</NavLink>
										</Nav>
									</Navbar>
								</Col>
							</Row>
						</Container>
						{/* {natal ? <div className="container-enfeite-nav-tema">
							<img src={images.NavBarEnfeiteNatal} className="enfeite-nav-tema " alt=""></img>
						</div> : ('')} */}
					</MenuDesk>
					<MenuMobile className="menu-mobile-nav">
						{/* <img src={images.BgNavNatalMobile} className="bg-nav-tema" alt=""></img> */}
						{/* <img src={images.BackgroundNavBf} className="bg-nav-tema" alt=""></img> */}
						<Navbar /*bg="light"*/ expand={false}>
							<Container className="p-0" fluid>
								<Link className="ps-3 navbar-brand" to="/home">
									<img className="logo-nav-bar-mobile" src={Logo} alt="" />
								</Link>
								<Navbar.Toggle
									className="me-3"
									aria-controls="offcanvasNavbar light"
								/>
								<Navbar.Offcanvas
									id="offcanvasNavbar"
									aria-labelledby="offcanvasNavbarLabel"
									placement="start"
								>
									<Offcanvas.Header closeButton>
										<Link
											className="offcanvas-title h5"
											id="offcanvasNavbarLabel"
											to="/home"
										>
											<img
												className="logo-nav-bar-mobile "
												style={{ width: 70, height: 45, marginLeft: 20 }}
												src={Logo}
												alt=""
											/>
										</Link>
									</Offcanvas.Header>
									<Offcanvas.Body style={{ background: cor }}>
										<Nav className="justify-content-end flex-grow-1 ps-5 pe-3">
											<NavLink className="nav-link text-light mb-2" to="/home" onClick={RemoveRedirectCampanha()}>
												Home
											</NavLink>
											<NavLink
												className="nav-link text-light mb-2"
												to="/sobre-maistop"
											>
												Sobre o +top
											</NavLink>
											<NavLink id="campanhas" className="nav-link text-light position-relative " to="/campanhas">
												Campanhas
												{campanhas > 0 ? <div className="notificacao-icon"><p>{campanhas}</p></div> : ('')}
											</NavLink>
											<Nav.Link className="text-light mb-2" href={"https://saibamais.data2.app/?t=" + token} target="_blank">
												Treinamentos
											</Nav.Link>
											<Nav.Link className="text-light mb-2 d-none" href="#">
												Publicar Planilhas
											</Nav.Link>
											<NavLink to="/minha-revenda" className=" nav-link text-light ">Minha Revenda</NavLink>
											<NavLink className="nav-link text-light mb-2" to="/cadastro-loja">
												Cadastro de Lojas
											</NavLink>
											<NavLink className="nav-link text-light mb-2" to="/extrato">
												Extrato
											</NavLink>
											{sessionStorage.getItem("acessoEspelho") === 'true' ? ('') :
												<Nav.Link
													className=" nav-link text-light mb-2"
													href="#pricing"
													onClick={abreAbaPremios}
												>
													Catálogo
												</Nav.Link>}
											{/* <Link className="nav-link text-light mb-2" to="/duvidas">
												Dúvidas
											</Link> */}

											<NavLink className="nav-link perfil" to="/meu-perfil">
												Meu perfil
											</NavLink>
											<NavLink
												className=" nav-link text-light mb-2"
												to="/"
												onClick={Logout}
											>
												Sair
											</NavLink>
										</Nav>
									</Offcanvas.Body>
								</Navbar.Offcanvas>
							</Container>
						</Navbar>
						{/* {natal ? <div className="container-enfeite-nav-tema">
							<img src={images.NavBarEnfeiteNatal} className="enfeite-nav-tema-mobile" alt=""></img>
						</div> : ('')} */}
					</MenuMobile>
				</>
			)}

			{user.cargo.descricao === "GERENTE" && (
				<>
					<MenuDesk style={{ zIndex: "1" }} className="menu-desk-nav">
						{/* <img src={images.NavBackgroundNatal} className="bg-nav-tema" alt=""></img> */}
						{/* <img src={images.BackgroundNavBf} className="bg-nav-tema" alt=""></img> */}
						<Container className="p-0">
							<Row className="p-0 m-0">
								<Col className="p-0 m-0">
									<Navbar className="items-container">
										<Link className="col-2 navbar-brand" to="/home">
											<img className="logo-nav-bar" src={Logo} alt="" />
										</Link>
										<div className="col-1"></div>
										<Nav
											className="col-9 d-flex justify-content-around navbar-itens"
										>
											<NavLink className="nav-link" to="/home" onClick={RemoveRedirectCampanha()}>
												Home
											</NavLink>
											<NavLink className="nav-link" to="/sobre-maistop">
												Sobre o +top
											</NavLink>
											<NavLink className="nav-link" to="/campanhas">
												Campanhas
												{campanhas > 0 ? <div className="notificacao-icon"><p>{campanhas}</p></div> : ('')}
											</NavLink>
											<NavLink className="nav-link" to={"https://saibamais.data2.app/?t=" + token} target="_blank">Treinamentos</NavLink>
											<NavLink className="nav-link" to="/minha-equipe" >Minha Equipe</NavLink>
											<NavLink className="nav-link" to="/gerenciar-equipe">Gerenciar Equipe</NavLink>
											<NavLink className="nav-link" to="/extrato">Extrato</NavLink>
											{/* {sessionStorage.getItem("acessoEspelho") === 'true' ? ('') : <NavLink to="/" onClick={abreAbaPremios}>
												Catálogo
											</NavLink>} */}
											{/* {mpv !== 'false' ? <NavLink className="mb-2" to="/material-loja">
												<b>Material Loja</b>
											</NavLink> : ('')} */}

											<NavLink className="nav-link" to="/convide-um-amigo" >Convide um amigo</NavLink>
											<Link className="nav-link perfil" to="/meu-perfil">
												Meu perfil
											</Link>
											<NavLink className="nav-link" to="/" onClick={Logout}>
												Sair
											</NavLink>
										</Nav>
									</Navbar>
								</Col>
							</Row>
						</Container>
						{/* {natal ? <div className="container-enfeite-nav-tema">
							<img src={images.NavBarEnfeiteNatal} className="enfeite-nav-tema " alt=""></img>
						</div> : ('')} */}
					</MenuDesk>
					<MenuMobile className="menu-mobile-nav">
						{/* <img src={images.BgNavNatalMobile} className="bg-nav-tema" alt=""></img> */}
						{/* <img src={images.BackgroundNavBf} className="bg-nav-tema" alt=""></img> */}
						<Navbar /*bg="light"*/ expand={false}>
							<Container className="p-0" fluid>
								<Link className="ps-3 navbar-brand" to="/home">
									<img className="logo-nav-bar-mobile" src={Logo} alt="" />
								</Link>
								<Navbar.Toggle
									className="me-3"
									aria-controls="offcanvasNavbar"
								/>
								<Navbar.Offcanvas
									id="offcanvasNavbar"
									aria-labelledby="offcanvasNavbarLabel"
									placement="start"
								>
									<Offcanvas.Header closeButton>
										<Link
											className="offcanvas-title h5"
											id="offcanvasNavbarLabel"
											to="/home"
										>
											<img
												className="logo-nav-bar-mobile "
												style={{ width: 70, height: 45, marginLeft: 20 }}
												src={Logo}
												alt=""
											/>
										</Link>
									</Offcanvas.Header>
									<Offcanvas.Body style={{ background: cor }}>
										<Nav className="justify-content-end flex-grow-1 ps-5 pe-3">
											<NavLink className="nav-link text-light mb-2" to="/home" onClick={RemoveRedirectCampanha()}>
												Home
											</NavLink>
											<NavLink
												className="nav-link text-light mb-2"
												to="/sobre-maistop"
											>
												Sobre o +top
											</NavLink>
											<NavLink id="campanhas" className="nav-link position-relative" to="/campanhas">
												Campanhas
												{campanhas > 0 ? <div className="notificacao-icon"><p>{campanhas}</p></div> : ('')}
											</NavLink>
											<NavLink className=" nav-link text-light mb-2" to={"https://saibamais.data2.app/?t=" + token} target="_blank">
												Treinamentos
											</NavLink>
											<NavLink className=" nav-link text-light mb-2 " to="/minha-equipe">
												Minha Equipe
											</NavLink>
											<NavLink className=" nav-link text-light mb-2 " to="/gerenciar-equipe">Gerenciar Equipe</NavLink>
											<NavLink className=" nav-link text-light mb-2" to="/extrato">
												Extrato
											</NavLink>
											{sessionStorage.getItem("acessoEspelho") === 'true' ? ('') : <NavLink
												className=" nav-link text-light mb-2"
												to="#pricing"
												onClick={abreAbaPremios}
											>
												Catálogo
											</NavLink>}
											<NavLink className=" nav-link text-light mb-2" to="/material-loja">
												Material Loja
											</NavLink>
											<NavLink className=" nav-link text-light mb-2 d-none" to="/convide-um-amigo">
												Convide um amigo
											</NavLink>
											{/* <Link className="nav-link text-light mb-2" to="/duvidas">
												Dúvidas
											</Link> */}

											<NavLink className="nav-link perfil" to="/meu-perfil">
												Meu perfil
											</NavLink>
											<NavLink
												className=" nav-link text-light mb-2"
												href="/"
												onClick={Logout}
											>
												Sair
											</NavLink>
										</Nav>
									</Offcanvas.Body>
								</Navbar.Offcanvas>
							</Container>
						</Navbar>
						{/* {natal ? <div className="container-enfeite-nav-tema">
							<img src={images.NavBarEnfeiteNatal} className="enfeite-nav-tema-mobile" alt=""></img>
						</div> : ('')} */}
					</MenuMobile>
				</>
			)}
			{(user.cargo.descricao === "GERENTE REGIONAL" || user.cargo.descricao === "DIRETOR")  && (
				<>
					<MenuDesk style={{ zIndex: "1" }}>
						{/* <img src={images.NavBackgroundNatal} className="bg-nav-tema" alt=""></img> */}
						{/* <img src={images.BackgroundNavBf} className="bg-nav-tema" alt=""></img> */}
						<Container className="p-0">
							<Row className="p-0 m-0">
								<Col className="p-0 m-0">
									<Navbar className="items-container">
										<Link className="col-2 navbar-brand" to="/home">
											<img className="logo-nav-bar" src={Logo} alt="" />
										</Link>
										<div className="col-1"></div>
										<Nav
											className="col-9 d-flex justify-content-around navbar-itens"
										>
											<NavLink className="nav-link" to="/home" onClick={RemoveRedirectCampanha()}>
												Home
											</NavLink>
											<NavLink className="nav-link" to="/sobre-maistop">
												Sobre o +top
											</NavLink>
											<NavLink className="nav-link" to="/campanhas">
												Campanhas
												{campanhas > 0 ? <div className="notificacao-icon"><p>{campanhas}</p></div> : ('')}
											</NavLink>
											<NavLink className="nav-link" to={"https://saibamais.data2.app/?t=" + token} target="_blank">Treinamentos</NavLink>
											<NavLink className="nav-link" to="/minha-equipe" >Minha Equipe</NavLink>
											<NavLink className="nav-link" to="/gerenciar-equipe">Gerenciar Equipe</NavLink>
											<NavLink className="nav-link" to="/extrato">Extrato</NavLink>
											{/* {sessionStorage.getItem("acessoEspelho") === 'true' ? ('') : <NavLink to="/" onClick={abreAbaPremios}>
												Catálogo
											</NavLink>} */}
											<NavLink className="nav-link" to="/convide-um-amigo">Convide um amigo</NavLink>
											<NavLink className="nav-link perfil" to="/meu-perfil">
												Meu perfil
											</NavLink>
											<NavLink className="nav-link" to="/" onClick={Logout}>
												Sair
											</NavLink>
										</Nav>
									</Navbar>
								</Col>
							</Row>
						</Container>
						{/* {natal ? <div className="container-enfeite-nav-tema">
							<img src={images.NavBarEnfeiteNatal} className="enfeite-nav-tema " alt=""></img>
						</div> : ('')} */}
					</MenuDesk>
					<MenuMobile className="menu-mobile-nav">
						{/* <img src={images.BgNavNatalMobile} className="bg-nav-tema" alt=""></img> */}
						{/* <img src={images.BackgroundNavBf} className="bg-nav-tema" alt=""></img> */}
						<Navbar /*bg="light"*/ expand={false}>
							<Container className="p-0" fluid>
								<Link className="ps-3 navbar-brand" to="/home">
									<img className="logo-nav-bar-mobile" src={Logo} alt="" />
								</Link>
								<Navbar.Toggle
									className="me-3"
									aria-controls="offcanvasNavbar"
								/>
								<Navbar.Offcanvas
									id="offcanvasNavbar"
									aria-labelledby="offcanvasNavbarLabel"
									placement="start"
								>
									<Offcanvas.Header closeButton>
										<Link
											className="offcanvas-title h5"
											id="offcanvasNavbarLabel"
											to="/home"
										>
											<img
												className="logo-nav-bar-mobile "
												style={{ width: 70, height: 45, marginLeft: 20 }}
												src={Logo}
												alt=""
											/>
										</Link>
									</Offcanvas.Header>
									<Offcanvas.Body style={{ background: cor }}>
										<Nav className="justify-content-end flex-grow-1 ps-5 pe-3">
											<NavLink className="nav-link text-light mb-2" to="/home" onClick={RemoveRedirectCampanha()}>
												Home
											</NavLink>
											<NavLink
												className="nav-link text-light mb-2"
												to="/sobre-maistop"
											>
												Sobre o +top
											</NavLink>
											<NavLink id="campanhas" className="nav-link text-light position-relative" to="/campanhas">
												Campanhas
												{campanhas > 0 ? <div className="notificacao-icon"><p>{campanhas}</p></div> : ('')}
											</NavLink>
											<NavLink className=" nav-link text-light mb-2" to={"https://saibamais.data2.app/?t=" + token} target="_blank">
												Treinamentos
											</NavLink>
											<NavLink className=" nav-link text-light mb-2" to="/minha-equipe">
												Minha Equipe
											</NavLink>
											<NavLink className=" nav-link text-light mb-2" to="/gerenciar-equipe">Gerenciar Equipe</NavLink>
											<NavLink className=" nav-link text-light mb-2" to="/extrato">
												Extrato
											</NavLink>
											{sessionStorage.getItem("acessoEspelho") === 'true' ? ('') : <NavLink
												className=" nav-link text-light mb-2"
												to="#pricing"
												onClick={abreAbaPremios}
											>
												Catálogo
											</NavLink>}
											<NavLink className=" nav-link text-light mb-2" to="/convide-um-amigo">
												Convide um amigo
											</NavLink>
											{/* <Link className="nav-link text-light mb-2" to="/duvidas">
												Dúvidas
											</Link> */}

											<Link className="nav-link perfil" to="/meu-perfil">
												Meu perfil
											</Link>
											<NavLink
												className=" nav-link text-light mb-2"
												to="/"
												onClick={Logout}
											>
												Sair
											</NavLink>
										</Nav>
									</Offcanvas.Body>
								</Navbar.Offcanvas>
							</Container>
						</Navbar>
						{/* {natal ? <div className="container-enfeite-nav-tema">
							<img src={images.NavBarEnfeiteNatal} className="enfeite-nav-tema-mobile" alt=""></img>
						</div> : ('')} */}
					</MenuMobile>
				</>
			)}

			{user.cargo.descricao === "VENDEDOR" && (
				<>
					<MenuDesk style={{ zIndex: "1" }}>
						{/* <img src={images.BackgroundNavBf} className="bg-nav-tema" alt=""></img> */}
						<Container className="p-0">
							<Row className="p-0 m-0">
								<Col className="p-0 m-0">
									<Navbar className="items-container">
										<Link className="col-2 navbar-brand" to="/home">
											<img className="logo-nav-bar" src={Logo} alt="" />
										</Link>
										<div className="col-1"></div>
										<Nav
											className="col-9 d-flex justify-content-around navbar-itens"
										>
											<NavLink className="nav-link" to="/home" onClick={RemoveRedirectCampanha()}>
												Home
											</NavLink>
											<NavLink className="nav-link" to="/sobre-maistop">
												Sobre o +top
											</NavLink>
											<NavLink className="nav-link" to="/campanhas">
												Campanhas
												{campanhas > 0 ? <div className="notificacao-icon"><p>{campanhas}</p></div> : ('')}
											</NavLink>
											<NavLink className="nav-link" to={"https://saibamais.data2.app/?t=" + token} target="_blank">Treinamentos</NavLink>
											<NavLink className="nav-link" to="/meu-desempenho-vendas">Meu Desempenho</NavLink>
											<NavLink className="nav-link" to="/extrato">Extrato</NavLink>
											{user.cargo.descricao !== "GESTOR DA INFORMACAO" ? (<NavLink className="nav-link" to="/convide-um-amigo">Convide um amigo</NavLink>) : ('')}
											{/* {sessionStorage.getItem("acessoEspelho") === 'true' ? ('') : <NavLink to="/" onClick={abreAbaPremios}>
												Catálogo
											</NavLink>} */}
											<NavLink className="nav-link perfil" to="/meu-perfil">
												Meu perfil
											</NavLink>
											<NavLink className="nav-link" to="/" onClick={Logout}>
												Sair
											</NavLink>
										</Nav>
									</Navbar>
								</Col>
							</Row>
						</Container>
						{/* {natal ? <div className="container-enfeite-nav-tema">
							<img src={images.NavBarEnfeiteNatal} className="enfeite-nav-tema " alt=""></img>
						</div> : ('')} */}
					</MenuDesk>
					<MenuMobile className="menu-mobile-nav">
						{/* <img src={images.BgNavNatalMobile} className="bg-nav-tema" alt=""></img> */}
						{/* <img src={images.BackgroundNavBf} className="bg-nav-tema" alt=""></img> */}
						<Navbar /*bg="light"*/ expand={false}>
							<Container className="p-0" fluid>
								<Link className="ps-3 navbar-brand" to="/home">
									<img className="logo-nav-bar-mobile" src={Logo} alt="" />
								</Link>
								<Navbar.Toggle
									className="me-3"
									aria-controls="offcanvasNavbar"
								/>
								<Navbar.Offcanvas
									id="offcanvasNavbar"
									aria-labelledby="offcanvasNavbarLabel"
									placement="start"
								>
									<Offcanvas.Header closeButton>
										<Link
											className="offcanvas-title h5"
											id="offcanvasNavbarLabel"
											to="/home"
										>
											<img
												className="logo-nav-bar-mobile "
												style={{ width: 70, height: 45, marginLeft: 20 }}
												src={Logo}
												alt=""
											/>
										</Link>
									</Offcanvas.Header>
									<Offcanvas.Body style={{ background: cor }}>
										<Nav className="justify-content-end flex-grow-1 ps-5 pe-3">
											<NavLink className="nav-link text-light mb-2" to="/home" onClick={RemoveRedirectCampanha()}>
												Home
											</NavLink>
											<NavLink
												className="nav-link text-light mb-2"
												to="/sobre-maistop"
											>
												Sobre o +top
											</NavLink>
											<NavLink id="campanhas" className="nav-link text-light position-relative" to="/campanhas">
												Campanhas
												{campanhas > 0 ? <div className="notificacao-icon"><p>{campanhas}</p></div> : ('')}
											</NavLink>
											<NavLink className=" nav-link text-light mb-2" to={"https://saibamais.data2.app/?t=" + token} target="_blank">
												Treinamentos
											</NavLink>
											<NavLink className=" nav-link text-light mb-2" to="/meu-desempenho-vendas">
												Meu desempenho
											</NavLink>
											<NavLink className="nav-link text-light mb-2" to="/extrato">
												Extrato
											</NavLink>
											{sessionStorage.getItem("acessoEspelho") === 'true' ? ('') : <NavLink
												className=" nav-link text-light mb-2"
												to="#pricing"
												onClick={abreAbaPremios}
											>
												Catálogo
											</NavLink>}
											<NavLink className="nav-link text-light mb-2" to="/convide-um-amigo">
												Convide um amigo
											</NavLink>
											{/* <Link className="nav-link text-light mb-2" to="/duvidas">
												Dúvidas
											</Link> */}

											<Link className="nav-link perfil" to="/meu-perfil">
												Meu perfil
											</Link>
											<NavLink
												className=" nav-link  text-light mb-2"
												to="/"
												onClick={Logout}
											>
												Sair
											</NavLink>
										</Nav>
									</Offcanvas.Body>
								</Navbar.Offcanvas>
							</Container>
						</Navbar>
						{/* {natal ? <div className="container-enfeite-nav-tema">
							<img src={images.NavBarEnfeiteNatal} className="enfeite-nav-tema-mobile" alt=""></img>
						</div> : ('')} */}
					</MenuMobile>
				</>
			)}
		</NavBarPrincipal>
	);
}
