import { GlobalStyle } from "./styles/global";
// import { GlobalStyleTheme } from "./themes/natal";
// import { GlobalBlackFridayStyle } from './themes/blackfriday';
import { LoginPage } from "./pages/Login";
import { LoginProvider } from "./hooks/useLogin";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { HomePage } from "./pages/Home";
import { EsqueceuSenha } from "./pages/EsqueceuSenha";
import { Duvidas } from "./pages/Duvidas";
import { Extrato } from "./pages/Extrato";
import { Cadastro } from "./pages/Cadastro";
import { CadastroSelfie } from "./pages/CadastroSelfie";
import { MeuPerfil } from "./pages/MeuPerfil";
import { SobreMaisTop } from "./pages/SobreMaisTop";
import { Erro } from "./pages/Erro";
import { ConvideUmAmigo } from "./pages/ConvideUmAmigo";
import { FaleConosco } from "./pages/FaleConosco";
import { HistoricoMensagens } from "./pages/HistoricoMensagens";
import { ChamadoAndamento } from "./pages/ChamadoAndamento";
import { Campanhas } from "./pages/Campanhas";
import { Politica } from "./pages/Politica";
import { Regulamento } from "./pages/Regulamento";
import { Planilhas } from "./pages/Planilhas";
import { AcessoEspelho } from "./pages/AcessoEspelho";
import TagManager from "react-gtm-module";
import { CadastroLoja } from "./pages/CadastroLoja";
import { Loja } from "./pages/CadastroLoja/Loja";
import { MinhaRevenda } from "./pages/MinhaRevenda";
import { MinhaEquipe } from "./pages/MinhaEquipe";
import { HistoricoConvideAmigo } from "./pages/ConvideUmAmigo/HistoricoConvideAmigo";
import { MeuDesempenho } from "./pages/MeuDesempenho";
import { MeuDesempenhoVendas } from "./pages/MeuDesempenhoVendas";
import { MaterialLoja } from "./pages/MaterialLoja";
import { TopNews } from "./pages/TopNews";
import { RenovarSenha } from "./pages/RenovarSenha"
import { TampaManutencao } from "./pages/TampaManutencao"
import { Error404 } from "./pages/404"
import { GerenciarEquipe } from "./pages/GerenciarEquipe"
import {ConfirmacaoLogin} from "./pages/ConfirmacaoLogin"
//import { useLogin } from "./hooks/useLogin";

export function App() {
  const loginStorage = sessionStorage.getItem("token");
  const UserInfo = JSON.parse(sessionStorage.getItem("UserInfo"));
  const manutencao = false;
  const url = window.location.href;
  const busca = url.search("/campanhas");

  if (busca > 0) {
    let urlnovo = url.slice(busca)
    let id = urlnovo.substring(11).split('/', 3)
    localStorage.setItem('redirect', urlnovo)
    localStorage.setItem('id', id[0])
  }

  if (url.search("/CadastroConvidado") > 0) {
    const buscaIdConvidado = url.search("/CadastroConvidado");
    let IdConvite = url.slice(buscaIdConvidado + 19);
    localStorage.setItem("IdConvite", IdConvite);
  }

  const tagManagerArgs = {
    gtmId: "GTM-5MQLRK3",
  };
  TagManager.initialize(tagManagerArgs);

  function RequireAuth({ children }) {
    let location = useLocation();
    if (!loginStorage) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
  }

  function RestrictAccessByDate({ children }) {
    const today = new Date();
    const dayOfMonth = today.getDate();
    if (dayOfMonth < 1 || dayOfMonth > 25) {
      return <Navigate to="/" replace />;
    }
    return children;
  }

  return (
    <LoginProvider>
      <Routes>
        {manutencao ? (
          <>
            <Route
              path="/"
              element={<TampaManutencao loginStorage={loginStorage} />}
            />
            <Route
              path="/login"
              element={<TampaManutencao loginStorage={loginStorage} />}
            />
          </>
        ) : (
          <>
            <Route path="/" element={<ConfirmacaoLogin  />} />
            <Route path="/login" element={<ConfirmacaoLogin  />} />
            <Route path="/esqueceu-senha" element={<EsqueceuSenha />} />
            <Route path="/renovar-senha" element={<RenovarSenha />} />
            <Route path="/cadastro" element={<Cadastro />} />
          </>
        )}
        <Route path="/AcessoEspelho" element={<AcessoEspelho />} />
        <Route path="/confirm-login" element={<ConfirmacaoLogin />} />
        <Route
          path="/cadastro-selfie"
          element={<CadastroSelfie cpfStorage={UserInfo} />}
        />
        <Route path="/erro" element={<Erro />} />
        <Route path="*" element={<Error404 />} />
        <Route
          path="/home"
          element={
            <RequireAuth>
              <HomePage user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/meu-perfil"
          element={
            <RequireAuth>
              <MeuPerfil user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/sobre-maistop"
          element={
            <RequireAuth>
              <SobreMaisTop user={UserInfo} />
            </RequireAuth>
          }
        />
        {UserInfo ? (
          UserInfo.cargo.descricao === "GERENTE" ||
          UserInfo.cargo.descricao === "GERENTE REGIONAL" ? (
            <Route
              path="/gerenciar-equipe"
              element={
                <RequireAuth>
                  <RestrictAccessByDate>
                    <GerenciarEquipe user={UserInfo} />
                  </RestrictAccessByDate>
                </RequireAuth>
              }
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <Route
          path="/minha-equipe"
          element={
            <RequireAuth>
              <MinhaEquipe user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/duvidas"
          element={
            <RequireAuth>
              <Duvidas user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/convide-um-amigo"
          element={
            <RequireAuth>
              <ConvideUmAmigo user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/convide-um-amigo/historico"
          element={
            <RequireAuth>
              <HistoricoConvideAmigo user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/fale-conosco"
          element={
            <RequireAuth>
              <FaleConosco user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/fale-conosco-mensagens"
          element={
            <RequireAuth>
              <HistoricoMensagens user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/chamado-andamento"
          element={
            <RequireAuth>
              <ChamadoAndamento user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/extrato"
          element={
            <RequireAuth>
              <Extrato user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/campanhas"
          element={
            <RequireAuth>
              <Campanhas user={UserInfo} />
            </RequireAuth>
          }
        >
          <Route path=":CampanhaId" element={<Campanhas user={UserInfo} />} />
        </Route>

        <Route
          path="/politica"
          element={
            <RequireAuth>
              <Politica />
            </RequireAuth>
          }
        />
        <Route
          path="/regulamento"
          element={
            <RequireAuth>
              <Regulamento />
            </RequireAuth>
          }
        />
        <Route
          path="/planilhas"
          element={
            <RequireAuth>
              <Planilhas user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/minha-revenda"
          element={
            <RequireAuth>
              <MinhaRevenda user={UserInfo} />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/minha-equipe"
          element={
            <RequireAuth>
              <MinhaEquipe user={UserInfo} />
            </RequireAuth>
          }

        /> */}
        <Route
          path="/cadastro-loja"
          element={
            <RequireAuth>
              <CadastroLoja user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/cadastro-loja/loja"
          element={
            <RequireAuth>
              <Loja user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/politica"
          element={
            <RequireAuth>
              <Politica />
            </RequireAuth>
          }
        />
        <Route
          path="/regulamento"
          element={
            <RequireAuth>
              <Regulamento />
            </RequireAuth>
          }
        />
        <Route
          path="/planilhas"
          element={
            <RequireAuth>
              <Planilhas user={UserInfo} />
            </RequireAuth>
          }
        />
        <Route
          path="/minha-revenda"
          element={
            <RequireAuth>
              <MinhaRevenda user={UserInfo} />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/minha-equipe"
          element={
            <RequireAuth>
              <MinhaEquipe user={UserInfo} />
            </RequireAuth>
          }
        /> */}
        <Route
          path="/cadastroconvidado"
          element={
            <RequireAuth>
              <Campanhas user={UserInfo} />
            </RequireAuth>
          }
        >
          <Route path=":codigoId" element={<Campanhas user={UserInfo} />} />
        </Route>
        <Route
          path="/meu-desempenho"
          element={<MeuDesempenho user={UserInfo} />}
        />
        <Route
          path="/meu-desempenho-vendas"
          element={<MeuDesempenhoVendas user={UserInfo} />}
        />
        <Route
          path="/material-loja"
          element={<MaterialLoja user={UserInfo} />}
        />
        <Route
          path="/top-news"
          element={
            <RequireAuth>
              <TopNews user={UserInfo} />
            </RequireAuth>
          }
        />
      </Routes>
      <GlobalStyle />
      {/* <GlobalBlackFridayStyle /> */}
      {/* <GlobalStyleTheme /> */}
    </LoginProvider>
  );
}
