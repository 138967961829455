export const rotasApi = {
    // Autenticação
    login: '/Autenticacao/Entrar',
    redefinicaoSenha: '/Autenticacao/RedefinicaoDeSenha',
    redefinicaoSenhaComSenhaAnterior: '/Autenticacao/RedefinicaoDeSenhaComSenhaAnterior',
    // Cadastro
    cadastroPrimeiroAcesso: '/Cadastro/PrimeiroAcesso/',
    cadastroPreCadastro: '/Cadastro/PreCadastro',
    cadastroEnviarImagemCPFouCNH: '/Cadastro/EnviarImagemCPFouCNH?',
    cadastroEnviarImagemSelfie: '/Cadastro/EnviarImagemSelfie?',
    //Campanhas
    listaCampanhas:'/Campanha/Listar',
    buscarCampanhaDetalhada:'/Campanha/ObterPorId/',
    participarCampanha:'/Campanha/Participar',
    buscarRankingCampanha:'/Campanha/Ranking/',
    campanhasDisponiveis:'/Campanha/ContarCampanhasSemParticipacao',
    enviarFotoVideoCampanha:'/CampanhaFotoVideo/Enviar',
    buscarFotoVideoCampanhaUsuario:'/CampanhaFotoVideo/ObterPorCampanhaUsuario',
    excluirFotoVideoCampanha:'/CampanhaFotoVideo/Excluir',
    statusParticipacaoCampanha:'/Campanha/StatusParticipacaoUsuario/',
    // Código Acesso
    codigoAcessoPreCadastro: '/CodigoAcesso/EnviarCodigoAcessoPreCadastro',
    codigoAcessoRedefinicaoSenha: '/CodigoAcesso/EnviarCodigoRedefinicaoSenha',
    codigoAcessoValidar: '/CodigoAcesso/ValidarCodigoAcesso',
    codigoAcessoEsqueceuSenha: '/CodigoAcesso/ValidarCodigoAcessoEsqueciMinhaSenha',
    // Usuário
    obterDadosCadastrais: '/Usuario/ObterDadosCadastrais/',
    atualizaFotoPerfil: '/Usuario/AtualizarFotoPerfil?idUsuario=',
    inativarUsuario: '/Usuario/InativarMinhaConta/',
    // Dúvidas
    buscaDuvidas: '/Duvidas/BuscarTodasAsDuvidas/',
    pesquisaDuvidas: 'Duvidas/BuscarDuvidaPorTitulo/titulo?titulo=',
    atualizaDadosCadastraisUsuario: '/Usuario/AtualizarDadosCadastrais/',
    atualizaDadosCadastraisEndereco: '/Usuario/AtualizarEndereco/',
    //Catálogo
    linkCatalogoPremios: '/Catalogo/Link',
    // Saldo
    saldo: '/Pontuacao/ObterSaldoUsuario',
    // Extrato
    extratoGeral: 'Extrato/ExtratoGeral',
    extratoVendasRealizadas: 'Extrato/VendasRealizadas',
    extratoPontosExpirados: 'Extrato/PontosExpirados',
    extratoResgates: 'Extrato/Resgates',
    //Sac
    abrirChamado: 'Sac/AbrirChamado',
    buscarChamado: 'Sac/BuscarChamado',
    buscarChamados: 'Sac/BuscarChamados',
    buscarTodosOsAssuntos: 'Sac/BuscarTodosOsAssuntos',
    enviarMensagem: 'Sac/EnviarMensagem',
    buscarTodasMensagensDaOcorrencia: 'Sac/BuscarTodasMensagensDaOcorrencia',
    consultarPesquisaSac: 'Sac/ConsultarPesquisaPendente',
    salvarNotaPesquisaSac: 'Sac/SalvarNotaPesquisa',
    //Produtos
    listaProdutosSuperTop: '/Produtos/ProdutosSuperTops',
    listaCategoriasParticipantes: '/Produtos/CategoriasParticipantes',
    listaProdutosPremium: '/Produtos/ProdutosPremium',
    //AceitePontuaçãoVendas
    AceitePontuacaoVendas: '/Venda/AceitePontuacaoVendas', 
    AceiteRegulamentoMarketingPoliticas:'/usuario/Aceites',
    //Planilhas G.I
    PublicarPlanilha:'/Planilhas/Publicar',
    StatusPlanilha:'/Planilhas/Status',
    ObterErrosPlanilha:'/Planilhas/Erros',
    //Conquistas Usuario
    ObterConquistas:'/Conquistas/ObterConquistasUsuarioV2',
    ObterMeuDesempenho:'/Conquistas/MeuDesempenho',
    // Minha REVENDA
    MinhaRevendaCadastro:'MinhaRevenda/Cadastro',
    MinhaRevendaTreinamento:'MinhaRevenda/Treinamentos',
    MinhaRevendaVendas: 'MinhaRevenda/Vendas',
    MinhaRevendaCampanhas: 'MinhaRevenda/Campanhas',
    MinhaRevendaCampanhasDaRevenda: 'MinhaRevenda/CampanhasDaRevenda',
    MinhaRevendaPowerBi: '/MinhaRevenda/DashboardEmbedUrl',
    // Minha Equipe
    MinhaEquipeCadastro:'MinhaEquipe/Cadastro',
    MinhaEquipeTreinamento:'MinhaEquipe/Treinamentos',
    MinhaEquipeVendas: 'MinhaEquipe/Vendas',
    MinhaEquipeCampanhas: 'MinhaEquipe/Campanhas',
    MinhaEquipeCampanhasDaRevenda: 'MinhaEquipe/CampanhasDaLoja',
    //Lojas
    ListarLojasDaRede: 'Loja/ListarLojasDaRede',
    BuscarLojaPorId:'Loja/BuscarUnitario',
    AtualizarLojaPorId: 'Loja/Atualizar',
    CadastrarLoja: 'Loja/Cadastrar',
    AtivarLoja: 'Loja/Ativar',
    InativarLoja:'Loja/Inativar',
    //Relátorio Minha Revenda
    RelatorioExportarCadastro: 'Relatorio/ExportarCadastro',
    RelatorioExportarTreinamentosMinhaRevenda: 'Relatorio/ExportarTreinamentosMinhaRevenda',
    RelatorioExportarVendasMinhaRevenda: 'Relatorio/ExportarVendasMinhaRevenda',
    RelatorioExportarCampanhasMinhaRevenda: 'Relatorio/ExportarCampanhasMinhaRevenda',
    RelatorioExportarCadastroPDF: 'Relatorio/ExportarCadastroPDF',
    RelatorioExportarTreinamentosMinhaRevendaPDF: 'Relatorio/ExportarTreinamentosMinhaRevendaPDF',
    RelatorioExportarVendasMinhaRevendaPDF: 'Relatorio/ExportarVendasMinhaRevendaPDF',
    RelatorioExportarCampanhasMinhaRevendaPDF: 'Relatorio/ExportarCampanhasMinhaRevendaPDF',
    //Relatório Minha Equipe
    RelatorioExportarCadastroMinhaEquipe: 'Relatorio/ExportarCadastroMinhaEquipe',
    RelatorioExportarTreinamentosMinhaEquipe: 'Relatorio/ExportarTreinamentosMinhaEquipe',
    RelatorioExportarVendasMinhaEquipe: 'Relatorio/ExportarVendasMinhaEquipe',
    RelatorioExportarCampanhasMinhaEquipe: 'Relatorio/ExportarCampanhasMinhaEquipe',
    RelatorioExportarCadastroMinhaEquipePDF: 'Relatorio/ExportarCadastroMinhaEquipePDF',
    RelatorioExportarTreinamentosMinhaEquipePDF: 'Relatorio/ExportarTreinamentosMinhaEquipePDF',
    RelatorioExportarVendasMinhaEquipePDF: 'Relatorio/ExportarVendasMinhaEquipePDF',
    RelatorioExportarCampanhasMinhaEquipePDF: 'Relatorio/ExportarCampanhasMinhaEquipePDF',
    //Pontuação aniversário
    PontuacaoAniversario: 'Pontuacao/ObterPontuacaoAniversarioNaoVisualizada',
    //Notificações usuário
    BuscarNotificacaoUsuario:'Notificacao/BuscarNotificacoesPorUsuario',
    MarcarNotificacaoLida:'Notificacao/NotificacaoLida',
    //Convide amigo
    ListarAmigosParaConvite:'ConvideAmigo/BuscarUsuariosPreCadastro',
    ConvidarAmigo:'ConvideAmigo/CadastrarConvidado',
    ObterCodigoConvite: 'ConvideAmigo/ObterCodigoConvite',
    UsuariosConvidados:'/ConvideAmigo/BuscarUsuariosConvidados',
    FiltrarUsuariosConvidados:'/ConvideAmigo/BuscarUsuariosConvidadosPorStatus',
    //Sorteio Rapadinha
    BuscarGanhadorRaspadinha: '/Sorteio/BuscarGanhadorRaspadinha',
    MarcarPremioVisualizado: '/Sorteio/MarcarVisualizado',
    //Pesquisa
    ConsultarPesquisa:'/Pesquisa/Consultar',
    SalvarReposta:'/Pesquisa/SalvarResposta',
    //Obter Banner
    ListarBanner:'/Banner/Listar',
    //Metas Campanhas
    ConsultarMeta:'/Meta/ConsultarMetasUsuario',
    //Meu desempenho
    MeuDesempenhoCampanha:'MeuDesempenho/Campanhas',
    MeuDesempenhoDadosVendas:'MeuDesempenho/DadosVendas',
    MeuDesempenhoDadosTreinamento:'MeuDesempenho/DadosTreinamento',
    MeuDesempenhoDadosDesafio:'MeuDesempenho/Conquistas',
    //MPV
    ListarMpvs:'Mpv/ListarMpvs',
    CadastrarMpvs:'Mpv/CadastrarPedido',
    ListarPedidos: 'Mpv/ListarPedidos',
    //Modal comunicado
    BuscarComunicado:'Modal/Listar',
    ConfirmarLeituraBanner: 'Modal/SalvarConfirmacao',
    //topnews
    ListarPostagens:'Publicacao/Listar',
    SalvarReacao:'Publicacao/SalvarReacao',
    BuscarDetalhesPublicacao:'Publicacao/BuscarDetalhes',
    //gerenciar minha equipe
    ListarEquipe:'/MinhaEquipe/ListarEquipe',
    InfoDetalhadaEquipe:'/GestaoEquipeSolicitacao/CriarSolicitacaoModal',
    GestaoEquipeSolicitacao:'/GestaoEquipeSolicitacao/CriarSolicitacao',
}