import styled from "styled-components";

export const LoginPage = styled.div`
  background-color: #f8f8f8;
  display: flex;
  overflow: hidden;
  color: #15151f; 
  @media (max-width: 992px) {
    height: 100vh;
    }

  .btn-type-1 {
    background-color: #ef4e23;
    border: none;
    height: 56px;
  }
  .btn-type-2 {
    background: transparent;
    border: 1px solid #ef4e23;
    color: #ef4e23;
    height: 56px;
  }
  .help-teste {
    position: absolute;
    bottom: -215px;
  }
  .body-card {
    width: 520px !important;
    @media (max-width: 992px) {
      width: 100% !important;
      /* padding: 1rem 3rem; */
    }
    .tempo-reenviar{
      span{
        background-color: #000;
        color: #fff;
        padding: 8px 24px;
        border-radius: 4px;
        text-align: center;
        @media (max-width: 992px) {
          padding: 16px 22px 8px 21px;
      }
      }

      p{
        padding-top: 8px;
        font-size: 14px;
      }
    }
  }
`;

export const LeftSide = styled.div`
  background-color: #ef4e23;
  width: 37%;
  height: 100vh;
  position: relative;
  @media (min-width: 992px) and (max-width: 1919px) {
        width: 47vw;
    }
  @media (max-width: 992px) {
    display: none;
  }
  .tom-mais-top {
    position: absolute;
    left: -100px;
    /* width: 1043px; */
    height: 110vh;
    @media (min-width: 992px) and (max-width: 1919px) {
        left: -80px;
        width: 56vw;
        top: 9vh;
    }
    }

  
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 63%;
  justify-content: center;
  
  @media (min-width: 1280px) and (max-width: 1919px) {
        .titulo-card{
          font-size: 25px!important;
        }
        hr{
          margin-top: -20px!important;
          margin-bottom: 10px!important;
        }
        .possui-cadastro{
          margin-top: 10px!important;
        }
    }
  @media (max-width: 992px) {
    .form-check-email{
              margin: 0px 0px 34px 0px!important;
            }
  }
  

  @media (min-width: 992px) and (max-width: 1919px) {
        width: 70vw;
    }
  .passo-1{
        display:inline;
      }
  @media (max-width: 992px) {
    width: 100%;
    .logo-mais-top {
      margin-bottom: auto!important;;
    }
    /* .passo-1 {
      display: none;
    } */
  }


  .logo-mais-top {
    width: 110px;
    z-index: 2;
    margin-bottom: 60px;
    @media (min-width: 992px) and (max-width: 1919px) {
      z-index: 2;
      margin-bottom: 0px;
    }
    /* @media (min-width: 992px) {
      margin-bottom: 0px!important;
    } */

    align-self: baseline;
    /* margin-bottom: 64px; */
  }
  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
  #rc-anchor-container {
    width: 100% !important;
  }
  .rc-anchor-normal {
    width: 100% !important;
  }
`;

export const CardLogin = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 992px) {
    width: 30rem;
    /* height: rem; */
  }
  @media (max-width: 992px) {
    flex-direction: column;
    padding: 1rem 3rem;
  }

  .login-padrao {
    background: #f8f8f8;
    @media (min-width: 992px) and (max-width: 1919px) {
        background:none!important;
    }
  }

  .botao-voltar {

    align-self: baseline;
    background: none;
    border: none;
    font-size: 16px;
    color: #ef4e23;
    @media (max-width: 992px) {
      margin-top: 20px;
    }
    svg {
      margin-right: 8px;
    }
  }

  .titulo-card {
    /* margin-top: 53px; */
    margin-bottom: 27px;
    font-size: 40px;
    font-weight: bold;
    color: #15151f;
    @media (max-width: 992px) {
      margin-top: 8px;
      font-size: 24px;
    }
    @media (max-height: 731px) {
      margin-top: 8px;
      margin-bottom: 10px;
    }
  }
  .card-login {
    /* box-shadow: 0 0 1rem rgb(0 0 0 / 15%); */
    border-radius: 0.5rem;
    background: #f8f8f8;
    .primeiro {
      margin-top: 20px;
    }
    .enviar-primeiro-acesso {
      height: 56px;
      background-color: #ef4e23;
    }
    @media (max-width: 992px) {
      width: 24rem;
    }
  }
  .pass-hide {
    top: 41px !important;
    right: 45px !important;
    cursor: pointer;
    @media (max-width: 992px) {
      top: 38px !important;
      right: 38px !important;
    }
  }
  .checkbox-senha {
    font-size: 12px;
    margin-top: 6px;
    position: absolute;
    top: -5px;
    right: 0;
    .link-secondary {
      color: #ef4e23;
    }
    span {
      margin-left: 5px;
    }
  }
  button {
    height: 45px;
    /* margin-bottom: 26px; */
  }
  .possui-cadastro {
    font-size: 14px;
    text-align: center;
    a {
      color: #ef4e23;
      text-decoration: none;
    }
  }
  .label-input {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .form-control {
    height: 56px !important;
    width: 488px;
    border-radius: 8px;
    @media (max-width: 992px) {
      width: 100%;
      height: 40px !important;
    }
    @media (min-width: 1280px) and (max-width: 1919px) {
        /* width: 410px; */
    }
  }
  .avisos {
    hr {
      margin-top: -5px;
      margin-bottom: 32px;
    }
    p {
      font-size: 14px;
    }
    .email {
      color: #ef4e23;
    }
    @media (max-width: 992px) {
    }
  }
  .info-telefone {
    font-size: 14px;
    /* color: #fff; */
    font-weight: 400;
    h5 {
      //styleName: Body/Body14 - Regular;
      /* font-family: Epilogue; */
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
    }
    @media (max-width: 992px) {
      font-size: 10px !important;
      /* margin-bottom: 31px; */
      height: 120px;
    }
    @media (max-height: 731px) {
      p {
        font-size: 10px;
      }
      h5 {
        font-size: 15px;
      }
    }
  }

  .info-telefone .strong {
    font-weight: 800;
  }
  .btn-login {
    height: 56px;
    background-color: #ef4e23;
    border: none;
    @media (max-width: 992px) {
      height: 40px !important;
      }
  }
  .esqueceu-senha {
    color: #15151f;
    background: #f8f8f8;
    h5 {
      margin-top: 25px;
      @media (max-width: 992px) {
        font-size: 24px !important;
      }
    }
    .cadastro {
      font-size: 14px;
    }
    .card-title {
      font-size: 32px;
      /* color: #15151F; */
    }
    .card-text {
      font-size: 14px;
    }
    .btn-primary {
      height: 56px;
      background-color: #ef4e23;
      border: none;
      margin-top: 40px !important;
    }
    .link-secondary {
      color: #ef4e23;
    }
  }
  .enviar-codigo {
    background: #f8f8f8;
    h5 {
      font-size: 32px;
      @media (max-width: 992px) {
        font-size: 24px;
      }
    }
    @media (max-width: 992px) {
        .verif,.reenviar{
          height: 40px!important;
        }
      }
    .verif {
      height: 56px;
      border: none;
      background-color: #ef4e23;
      margin-bottom: 16px;
      margin-top: 40px;
      
    }
    .reenviar {
      height: 56px;
      border: 1px solid #ef4e23;
      background: none;
      color: #ef4e23;
    }
    .sub-title {
      font-size: 14px;
      .email{
      font-size: 16px!important;
      }
    }
    .link-secondary {
      color: #ef4e23;
    }
  }
  .escolher-envio {
    font-size: 14px;
    color: #15151f;
    background: none;
    h5 {
      font-size: 32px;
      margin-bottom: 16px;
      @media (max-width: 992px) {
        font-size: 24px;
      }
    }
    .card-text {
      margin-bottom: 40px;
    }
    button {
      height: 56px;
      margin-bottom: 16px;
      :first {
        /* #EF4E23 */
        background-color: #ef4e23;
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .cadeado-icon {
      width: 50px;
      align-self: center;
      margin-bottom: 16px;
    }
    .form-check {
      /* align-self: center; */
      /* text-align: center; */
      .form-check-label{
        margin-left: 10px;
      }
    }
  }

  .digita-codigo {
    color: #15151f;
    background: #f8f8f8;
    @media (max-width: 992px) {
      .card-title {
        font-size: 24px;
      }
      .icon-size {
        display: none;
      }
      /* .form-control {
        width: 314px !important;
        font-size: 74px!important;
      } */
        button{
          height: 40px;
        }
    }

    .card-body {
      width: 400px;
      @media (max-width: 992px) {
        width: 328px !important;
      }
    }
    .card-text {
      font-size: 14px;
    }
    h5 {
      font-size: 32px;
    }
    .card {
      border: none !important;
    }
    .text {
      font-weight: bold;
      text-align: center;
    }
    .enfeite {
      width: 366px;
      height: 30px;
      align-self: center;
      /* border: 1px solid #000; */
      display: flex;
      justify-content: space-between;
      div {
        border-bottom: 5px solid;
        width: 48px;
      }
      @media (max-width: 992px) {
        /* width: 328px!important; */
        width: 299px;
        div {
          width: 40px;
        }
      }
    }
    .btn-type-1 {
      margin-top: 50px;
      margin-bottom: 16px;
    }
    .input-codigo{
      margin-top: 30px;
    }
  }
  .escreve-nova-senha {
    color: #15151f;
    background: #f8f8f8;
    h5 {
      font-size: 32px;
      margin-bottom: 40px;
      @media (max-width: 992px) {
        font-size: 24px;
        margin-top: 25px;
    }
    }
    .small {
      font-size: 12px;
    }
    @media (max-width: 992px) {
        button{
          height: 40px;
        }
    }
  }
  .mensagem-senha {
    color: #15151f;
    text-align: center;
    background: #f8f8f8;
    h5 {
      font-size: 32px;
    }
  }
`;

export const DigitInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  input {
    border: none;
    width: 77% !important;
    font-size: 103px;
    height: 138px !important;
    align-self: center !important;
    @media (max-width: 992px) {
      width: auto !important;
    }
  }
  hr {
    border-bottom: 25px dashed #000;
    height: 14px;
    color: unset;
    background-color: transparent;
    opacity: 1;
  }
  .form-control {
    height: 106px !important;
    background: #f8f8f8;
  }
`;
