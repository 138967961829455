import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import { rotasApi } from "../services/rotasApi";

export const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: "",
  loginState: "",
};

// A slice for recipes with our 3 reducers
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    getLogin: (state) => {
      state.loading = true;
    },
    getLoginSuccess: (state, { payload }) => {
      state.loginState = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getLoginFailure: (state, { payload }) => {
      state.errorMessage = payload;
      state.loading = false;
      state.hasErrors = true;
    },
    getLoginReset: (state) => {
      state.loginState = "";
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = "";
    },
  },
});

// The reducer
export default loginSlice.reducer;

export const loginSelector = (state) => state.login;

export const { getLogin, getLoginSuccess, getLoginFailure, getLoginReset } =
  loginSlice.actions;

// Asynchronous thunk action
export function loginAction(data) {
  return async (dispatch) => {
    try {
      dispatch(getLoginReset());
      dispatch(getLogin());

      const formData = new FormData();
      formData.append("Cpf", data.cpf);
      formData.append("Senha", data.senha);
      formData.append("token", data.recaptcha);
      formData.append("Sistema", 1);
      formData.append("Ip", data.ip);
      formData.append("AppId", "");

      const result = await api.post(rotasApi.login, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        validateStatus: function (status) {
          // Aceitar todos os códigos de status
          return true;
        },
      });


      if (!result.data.success) {
        console.log("Falha no login:", result.data);
        return dispatch(
          getLoginFailure(result.data.message ? result.data.message : "")
        );
      } else {
        // Verificação se a mensagem contém "foi enviado"
        if (result.data.message && result.data.message.includes("foi enviado")) {
          // console.log("E-mail enviado com sucesso:", result.data.message);

          sessionStorage.setItem(
            "UserInfoNoValidation",
            JSON.stringify({
              "Cpf": data.cpf,
              "Senha": data.senha
            })
          );
          const email = result.data.message.match(/e-mail:\s*(.+)$/)[1];
          return dispatch(getLoginSuccess(result.data.usuario ? result.data.usuario.status : { mensagem: 'Verificação enviada', email: email }));
        } else if (result.data.message === "Usuário encontrado com sucesso") {
          if (result.data.usuario.status === "PENDENTE_ENVIO_DE_DOCUMENTO") {
            sessionStorage.setItem(
              "UserInfo",
              JSON.stringify(result.data.usuario)
            );
            window.location.href = "/cadastro-selfie";
          } else if (result.data.usuario.status === "MODERACAO_DE_DOCUMENTO") {
            return dispatch(getLoginSuccess(result.data.usuario.status));
          } else {
            sessionStorage.setItem("token", result.data.token);
            sessionStorage.setItem(
              "UserInfo",
              JSON.stringify(result.data.usuario)
            );
            window.location.href = '/home'
            return dispatch(getLoginSuccess(result.data.usuario));
          }
        }

        // else if (result.data.usuario.status === "ATIVO") {
        //   sessionStorage.setItem("token", result.data.token);
        //   sessionStorage.setItem(
        //     "UserInfo",
        //     JSON.stringify(result.data.usuario)
        //   );

        //   window.location.href = '/home'
        //   // return dispatch(getLoginSuccess(result.data.usuario));
        //   return dispatch(getLoginSuccess(result.data.usuario));
        // }
        if (result.data.message === "Usuário somente catalogo") {
          const url = result.data.link;
          if (url.length > 0) {
            window.open(`${url}`, "_self");
            return;
          }
        }
      }

    } catch (error) {
      console.log("Erro capturado no catch:", error); // Log detalhado do erro
      console.log("Detalhes do erro de resposta:", error.response);
      return dispatch(
        getLoginFailure(
          error.response && error.response.data && error.response.data.mensagem
            ? error.response.data.mensagem
            : "Ocorreu um erro inesperado. Feche o aplicativo e abra novamente."
        )
      );
    }
  };
}

export function loginConfirmCode(data) {
  return async (dispatch) => {
    try {
      dispatch(getLoginReset());
      dispatch(getLogin());
      const teste = JSON.parse(sessionStorage.getItem("UserInfoNoValidation"));
      const formData = new FormData();

      formData.append("Cpf", teste.Cpf);
      formData.append("Senha", teste.Senha);
      formData.append("token", data.recaptcha);
      formData.append("Sistema", 1);
      if (!data.reenvio) {
        if(data.codigo2fa===''){
          formData.append("Codigo2fa", '#####');
        }
        else{
          formData.append("Codigo2fa", data.codigo2fa);
        }
        
      }

      const result = await api.post(rotasApi.login, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        validateStatus: function (status) {
          return true;
        },
      });
      if (!result.data.success) {
        console.log("Falha no login:", result.data);
        return dispatch(
          getLoginFailure(result.data.message ? result.data.message : "")
        );
      } else {
        if (data.recaptcha === true) {
          // console.log('testeeeeeeeeeeeeeeeeeeeeeeeeeeeee', data.recaptcha)
          return dispatch(getLoginSuccess({ mensagem: 'Verificação enviada' }));

        }
        if (result.data.message === "Usuário encontrado com sucesso") {
          if (result.data.usuario.status === "PENDENTE_ENVIO_DE_DOCUMENTO") {
            sessionStorage.setItem(
              "UserInfo",
              JSON.stringify(result.data.usuario)
            );
            window.location.href = "/cadastro-selfie";
          } else if (result.data.usuario.status === "MODERACAO_DE_DOCUMENTO") {
            return dispatch(getLoginSuccess(result.data.usuario.status));
          } else {
            sessionStorage.setItem("token", result.data.token);
            sessionStorage.setItem(
              "UserInfo",
              JSON.stringify(result.data.usuario)
            );

            window.location.href = '/home'
            return dispatch(getLoginSuccess(result.data.usuario));

          }
        }
        if (result.data.message === "Usuário somente catalogo") {
          const url = result.data.link;
          if (url.length > 0) {
            window.open(`${url}`, "_self");
            return;
          }
        }
      }

    } catch (error) {
      console.log("Erro capturado no catch:", error); // Log detalhado do erro
      console.log("Detalhes do erro de resposta:", error.response);
      return dispatch(
        getLoginFailure(
          error.response && error.response.data && error.response.data.mensagem
            ? error.response.data.mensagem
            : "Ocorreu um erro inesperado. Feche o aplicativo e abra novamente."
        )
      );
    }
  };
}
