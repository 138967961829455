import { Modal, Button } from "react-bootstrap";
import images from "../../images/images";
import React, { useState } from "react";
import { CloseButton } from "./styles";
import { useEffect } from "react";


export function ModalAvisoGenerico({ modalShow, mensagem, error, modalData }) {


    const [modalShow1, setModalShow1] = useState(modalData.show);
    const handleClose = () => setModalShow1(false);

    useEffect(() => {
        setModalShow1(modalData.show)
        console.log('testeeeeeeeeeeeeeeee', modalData)
    }, [modalData.show]);
    return (


        <Modal
            show={modalShow1}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton onClick={() => { setModalShow1(false) }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {/* <h4>{modalData.error ? 'ATENÇÃO!' : 'SUCESSO!'}</h4> */}
                    {modalData.error ?
                        <>
                            <h4 style={{ color: 'red' }}>Atenção!</h4>
                        </>
                        : <>
                            <h4 style={{ color: 'green' }}>Sucesso!</h4>
                        </>

                    }

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {modalData.mensagem}
                </p>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button className="btn-verde" onClick={() => { setModalShow1(false) }}>Sim</Button> */}
                <Button className={modalData.error ? "btn-danger" : "btn-verde"} onClick={() => { setModalShow1(false) }}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}
