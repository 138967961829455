import styled from "styled-components";

export const Botao = styled.div`
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    border-end-end-radius: 3px;
    border-end-start-radius: 3px;
`
export const Content = styled.div`
    text-align: center;
    position: relative;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 1; */
    .texto{
        margin-top: 60px;
        padding: 0px 25px;
    }

    h3{
        position: absolute;
        top: 20px;
        width: 100%;
    }
    .success{
        color: #fff;
        font-size: 19px;
        font-weight: 600;
    }
`
