import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { Botao, Content } from "./styles";
import { useEffect } from "react";
export function ModalAvisoRequisicao({

  modalData,
  turnOffModal,
}) {
  const [modalShow1, setModalShow1] = useState(modalData.show);

  const handleClose = () => {
    setModalShow1(false);
    turnOffModal(false);
  };

  useEffect(() => {
    setModalShow1(modalData.show);
  }, [modalData.show]);

  return (
    <Modal
      show={modalShow1}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Content>
        {modalData.error ? (
          <h3>
            <i
              className="bi bi-exclamation-triangle-fill"
              style={{ color: "red", fontSize: "60px" }}
            ></i>
            <h4 style={{ color: "red" }}>Opss! </h4>
          </h3>
        ) : (
          <h3 style={{ marginTop:"27px" }}>
            <i
              class="bi bi-send-check"
              style={{ color: "#9CCB3D", fontSize: "60px" }}
            ></i>
            <h4 style={{ color: "#9CCB3D" }}>Sucesso!</h4>
          </h3>
        )}

        <p className="texto">{modalData.mensagem}</p>
        <Botao
          className={modalData.error ? "btn-danger" : "btn-verde success"}
          onClick={() => {
            handleClose(false);
          }}
        >
          Fechar
        </Botao>
      </Content>
    </Modal>
  );
}
