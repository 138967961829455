import styled from 'styled-components'
export const Container = styled.div`
    background-color: rgb(239, 238, 237);
    .container{
        /* padding-left: 51px; */
        @media (max-width: 768px) {    
            padding-left: 10px;
         }
    }
`;


export const Content = styled.div`
color: #15151F;
height: max-content;
/* display: flex;
justify-content: center; */
    .titulo-page{
        font-size: 40px;
        line-height: 48px;
        margin: 64px 0px;
        position: relative;
        @media (max-width: 768px) {    
            
                display: none;
            
         }
    }
    .titulo-page-mobile{
        display: none;

        @media (max-width: 768px) {    
            display: block;
            font-size: 32px;
            margin: 48px 0px;
            .titulo-page{
                display: none;
            }
         }
    }
    
`;

export const ContainerInfo = styled.div`
    /* background-color: red; */
    background-color: #fff;
    border:1px #EDEDEE ;
    border-radius: 8px;
    hr{
            margin-bottom: 30px;
        }
    .busca{
        display: flex;
        justify-content: space-between;
        margin: 0px 32px;
        align-items: center;
        @media (max-width: 768px) {    
            flex-direction: column;
         }
        h2{
            font-size: 24px;
            margin: 36px 0px 10px 0px;
            position: relative;
            @media (max-width: 768px) {    
                margin: 20px 0px;
         }
        }
        .form-busca {
            position: relative;
            width: 367px;
            margin: 36px 0px 10px 0px;
            @media (max-width: 768px) {    
                margin: 0px 0px 10px 0px;
         }
        }
        
    .btn-buscar {
		border: none;
		position: absolute;
		right: 15px;
		background: #fff;
		font-size: 24px;
		top: 12px;
		color: #ccc;

		@media (max-width: 769px) {
			position: absolute;
            top: 5px;
            right: 4px;
            width: 50px;
		}
	}
    .input-busca {
		width: 100%;
		max-width: 552px;
		border: 1px solid #c4c4c4;
		/* margin: 60px auto; */
		border-radius: 5px;
		height: 56px;
		padding-left: 14px;
		color: #313131;
		font-size: 16px;
		&::placeholder {
			color: #c4c4c4;
		}
	}
    
    }
    .tabela{
        margin: 0px 10px 57px 24px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        @media (max-width: 769px) {
			margin: 0px 0px 50px 13px;
		}
        .table-bordered>:not(caption)>*>*{
            border: none;
        }
        table{
            border-collapse: collapse;
        }
        thead{
            /* height: 54px; */
            /* margin: 16px 0px!important; */
            border-radius: 8px;
            border-bottom: 3px solid #F5F6FA;
          tr{
                background: #F5F6FA;
                /* background-color: red; */
                /* border-radius: 8px; */
                
                /* height: 54px; */
                p{
                    margin: 10px 0px;
                    font-weight: 500;
                    
                }
            }  
        }
        tbody{
            p{
                    margin: 10px 0px;
                    font-size: 12px;
                } 
                img{
                    margin-right: 7px;
                }
        }
        .status{
            border-radius: 14px;
            color: #fff;
            padding: 0px 7px;
            font-size: 14px;
            width: min-content;
        }
        .atv{
            background-color: #00B633;
        }
        .afstd{
            background-color: #FFB906;
            
        }
        .dlgd{
            background-color:#FF0000;
        }
        .editar-btn{
            border: none;
            background: none;
            margin-top: 11px;
        }
        
    }
    .pagination{
        align-self: center;
       .page-link{
        /* border: none; */
            color: #000;
            padding: 0px!important;
            border: none!important;
            width: 30px;
            height: 30px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        } 
        .page-item.active{
            background-color: none!important;
            /* width: 30px!important;
            height: 30px!important;
            padding: 6px; */
            
            span{
                background-color: white;
                border: 1px solid #dee2e6;
                color: #EF4E23!important;
            }
        }
    }
    
`;

export const GerenciarEquipeTabela = styled.div`
    /* background-color: red; */
    padding-left: 80px;
    @media (max-width: 768px) {    
            padding-left: 0px!important;
         }
`;
export const GerenciarEquipeDados = styled.div`
    /* background-color: red; */
    padding-left: 104px;
    @media (max-width: 768px) {    
            padding-left: 3px!important;
         }
    .title-page{
        color: #313131;
        margin-bottom: 64px;
        margin-top: 64px;
        p{
            font-size: 16px;
        }
    }
    .container{
        
         /* padding-left: 51px; */
        /* @media (max-width: 768px) {    
            padding-left: 10px;
         } */
        
    }
`;

export const ContainerDados = styled.div`
    background-color: #fff;
    border:1px #EDEDEE ;
    border-radius: 8px;
    padding: 32px 24px;    margin-bottom: 100px;
    margin-bottom: 100px;
    .form-select-lg{
        height: 56px!important;
    }
    h3{
        font-size: 20px;
        margin-bottom: 16px;
        color: #000;
    }
    label{
        font-size:14px;
    }
    textarea{
        resize: none;
        height: 99px!important;
        /* margin-bottom: 48px; */
    }
    .input-dados{
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;
        @media (max-width: 769px) {
			flex-direction: column;
            height: 165px;
            div{
                width: 100%!important;
            }
		}
        div{
            width: 49%;
            height: 58px;
            input{
                height: 56px;
            }
        }
    }
    .input-situacao{
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;
        @media (max-width: 769px) {
			flex-direction: column;
            height: 290px;
            div{
                width: 100%!important;
            }
		}
        div{
            width: 20%;
            height: 58px;
            input{
                height: 56px;
            }
        }
        .invalid-feedback{
            width: 100%;
        }
    }
    .envio-arquivo{
        border: 1px dashed #F37756;
        height: 100px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin-bottom: 70px;
        cursor: pointer;
        svg{
            margin-bottom: 10px;
        }
        a{
            color: #F37756;
            text-decoration: none;
        }
        span{
            font-size: 14px;
            position: absolute;
            left: 0;
            bottom: -30px;
        }
    }
    .arquivo-enviado{
        display: flex;
        justify-content: space-between;
        background-color: #EDEDEE;
        border-radius: 8px;
        padding: 16px;
        margin-top: 55px;
        .nome{
            display: flex;
            justify-content: center;
            align-items: center;
            .nome-arquivo{
                
            }
            .detalhes-arquivo{
                display: flex;
                flex-direction: column;
                margin-left: 16px;
            }
        }
        .close-icon{
            align-self: center;
            cursor: pointer;
        }
    }
    .botoes-acao{
        display: flex;
        justify-content: center;
        margin-top: 56px;
        .enviar,
        .cancelar{
            height: 56px;
            width: 384px;
            border-radius: 8px;
            padding: 16px;
            
        }
        .enviar{
            background-color: #EF4E23;
            color: #fff;
            border: none;
            margin-right: 32px;
            @media (max-width: 769px) {
                margin-right: 0px;
                margin-bottom: 16px;
		    }
        }
        .cancelar{
            color: #EF4E23;
            border:1px solid #EF4E23;
           background: none;
        }
    }
    
`


