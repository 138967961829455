import { Card, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";
import { RightSide, LeftSide, LoginPage, CardLogin } from "./styles";
import * as yup from "yup";
import images from "../../images/images";
import LogoDesk from "../../images/img/logo.svg";
import iconChecked from "../../images/img/icon-checked.svg";
import { Formik } from "formik";
import {
  primeiroAcessoAction,
  primeiroAcessoSelector,
  primeiroAcessoReset,
} from "../../slices/primeiroAcessoSlice";
import {
  loginAction,
  getLoginReset,
  loginConfirmCode,
} from "../../slices/loginSlice";
import { ModalAvisoRequisicao } from "../../components/ModalAvisoRequisicao";
import { LoadingArquivo } from "../../components/LoadingArquivo";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
const sitekey = "6Ld482QbAAAAAM8iW9B-FKXtMj8Hde-TTzj62-PP";
const loginStorage = sessionStorage.getItem("token");

export function ConfirmacaoLogin() {
  const [loginData, setLoginData] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passo, setPasso] = useState(1);
  const [cpf, setCpf] = useState("11122233344");
  const [emailFinal, setEmailFinal] = useState("@hotmail.com");
  const [emailForm, setEmailForm] = useState("exemplo-email123");
  const [telForm, setTelForm] = useState("11985886715");
  const [changeEye, setChangeEye] = useState("bi bi-eye-slash pass-hide");
  const dispatch = useDispatch();
  const [limparStore, setLimparStore] = useState(true);
  const [escolhaForm, setEscolhaForm] = useState("");
  const [reenviaCodigo, setReenviaCodigo] = useState(true);
  const [codigo, setCodigo] = useState("");
  const [dicaEmail, setDicaEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState({
    show: false,
    mensagem: "",
    error: true,
  });
  const [time, setTime] = useState(120); // 120 segundos = 2 minutos
  let navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const {
    primeiroAcessoState,
    primeiroAcessoLoading,
    primeiroAcessoErrors,
    primeiroAcessoMessage,
  } = useSelector(primeiroAcessoSelector);

  const [error, setError] = useState("");
  const [habilitarVerificarCod, setHabilitarVerificarCod] = useState(true);

  const handleChangeVerif = (e) => {
    const value = e.target.value;
    if (value.trim() === "") {
      setError("Este campo é obrigatório.");
      setHabilitarVerificarCod(true);
    } else {
      setError("");
      setHabilitarVerificarCod(false);
    }
  };

  useEffect(() => {
    if (loginData) {
      setIsLoading(true);
      axios
        .get("https://api.ipify.org?format=json")
        .then((result) => {
          loginData.ip = result.data.ip;
          dispatch(loginAction(loginData))
            .then((response) => {
              if (response.payload.mensagem === "Verificação enviada") {
                setPasso(4);
                setDicaEmail(response.payload.email);
                setTime(120);
              } else if (response.type === "login/getLoginFailure") {
                setModalData({
                  show: true,
                  mensagem: response.payload,
                  error: true,
                });
              } else if (response.payload === "MODERACAO_DE_DOCUMENTO") {
                setModalData({
                  show: true,
                  mensagem:
                    "Ainda em processo de moderação de documento, aguarde.",
                  error: true,
                });
              } else {
                setModalData({
                  show: true,
                  mensagem: response.payload,
                  error: true,
                });
              }
            })
            .catch((error) => {
              console.log("Erro :", error);
              setModalData({
                show: true,
                mensagem: error.response.data.mensagem,
                error: true,
              });
            })
            .finally(function() {
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
              setIsLoading(false);
            });
        })
        .catch((error) => {
          console.log(error.response);
          setModalData({
            show: true,
            mensagem: error.response.data.mensagem,
            error: true,
          });
        });
    }
  }, [loginData, dispatch]);

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
      setReenviaCodigo(false);
    }
  }, [time]);

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // const formatTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  // };

  const turnOffModal = (e) => {
    setModalData({
      show: false,
      mensagem: "",
      error: true,
    });
  };

  const handleSaveKey = (values) => {
    setIsLoading(true);
    console.log("==============>", values);
    dispatch(loginConfirmCode(values))
      .then((response) => {
        if (typeof response === "undefined") {
          setTime(120);
          setReenviaCodigo(true);
          setModalData({
            show: true,
            mensagem: "Código de verificação enviado novamente.",
            error: false,
          });
        }
        if (response && response.type !== undefined) {
          if (response.type === "login/getLoginSuccess") {
            if (response.payload === "MODERACAO_DE_DOCUMENTO") {
              setModalData({
                show: true,
                mensagem:
                  "Você se encontra em moderação de documento, aguarde.",
                error: true,
              });
            }
            // if(response.payload.mensagem === "Verificação enviada"){
            //   setModalData({
            //     show: true,
            //     mensagem: 'Código de verificação enviado novamente.',
            //     error: false,
            //   });
            // }
          } else if (response.type === "login/getLoginFailure") {
            setModalData({
              show: true,
              mensagem: response.payload,
              error: true,
            });
          }
        }
      })
      // .catch((error) => {
      //   setModalData({
      //     show: true,
      //     mensagem: error.response.data.mensagem,
      //     error: true,
      //   });
      // })
      .finally(function() {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setIsLoading(false);
      });
  };

  const validationSchemaEscolha = yup.object().shape({
    escolha: yup.string().required("Escolha uma opção"),
  });

  const validationSchemaCodigo = yup.object().shape({
    codigo: yup
      .string()
      .min(6, "Código deve ser preenchido")
      .required("Código é obrigatório"),
  });

  const handleLoginEscolha = (values) => {
    setIsLoading(true);
    values.cpf = cpf;
    setEscolhaForm(values.escolha);
    setReenviaCodigo(true);

    const formData = new FormData();

    formData.append("Cpf", cpf);

    if (values.escolha === "email" || escolhaForm === "email") {
      formData.append("EnviarPorEmail", true);
      formData.append("EnviarPorSMS", false);
    } else {
      formData.append("EnviarPorEmail", false);
      formData.append("EnviarPorSMS", true);
    }

    api
      .post(rotasApi.codigoAcessoRedefinicaoSenha, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          frontmt: "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          SkipAuth: "true",
        },
      })
      .then((result) => {
        if (result.data.sucesso) {
          setPasso(6);
          setTime(120);
        } else {
          setModalData({
            show: true,
            mensagem: result.data.mensagem,
            error: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalData({
          show: true,
          mensagem: error.response.data.mensagem,
          error: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLoginEscolhaReenviar = () => {
    setIsLoading(true);
    setReenviaCodigo(true);

    const formData = new FormData();
    formData.append("Cpf", cpf);

    if (escolhaForm === "email") {
      formData.append("EnviarPorEmail", true);
      formData.append("EnviarPorSMS", false);
    } else {
      formData.append("EnviarPorEmail", false);
      formData.append("EnviarPorSMS", true);
    }

    api
      .post(rotasApi.codigoAcessoRedefinicaoSenha, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          frontmt: "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          SkipAuth: "true",
        },
      })
      .then((result) => {
        if (result.data.sucesso) {
          setPasso(6);
          setTime(120);
        } else {
          setModalData({
            show: true,
            mensagem: result.data.mensagem,
            error: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalData({
          show: true,
          mensagem: error.response.data.mensagem,
          error: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLoginNovaSenha = (values) => {
    const formData = new FormData();
    formData.append("Cpf", cpf);
    formData.append("Senha", values.senha);
    formData.append("Codigo", codigo);

    api
      .post(rotasApi.redefinicaoSenha, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          frontmt: "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          SkipAuth: "true",
        },
      })
      .then((result) => {
        if (result.data.sucesso) {
          if (passo === 4) {
            setPasso(passo + 1);
          }
          setPasso(8);
        } else {
          setModalData({
            show: true,
            mensagem: result.data.mensagem,
            error: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalData({
          show: true,
          mensagem: error.response.data.mensagem,
          error: true,
        });
      });
  };
  const validationSchemaPassword = yup.object().shape({
    senha: yup
      .string()
      .min(8, "Senha deve ter pelo menos 8 caracteres")
      .matches(/^(?=.*?[A-Z])(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/, {
        message: "Senha inválida",
        excludeEmptyString: true,
      })
      .required("Senha é obrigatório"),
    confirmaSenha: yup.string().when("senha", (value, schema) => {
      return schema
        .oneOf([yup.ref("senha")], "As senhas devem ser iguais")
        .required("Confirmar Senha é obrigatório");
    }),
  });
  const handleLoginCodigo = (values) => {
    setCodigo(values.codigo.toUpperCase());
    const formData = new FormData();
    formData.append("Cpf", cpf);
    formData.append("CodigoAcesso", values.codigo.toUpperCase());

    api
      .post(rotasApi.codigoAcessoEsqueceuSenha, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          frontmt: "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          SkipAuth: "true",
        },
      })
      .then((result) => {
        if (result.data.sucesso) {
          if (passo === 3) {
            setPasso(passo + 1);
          }
          setPasso(7);
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalData({
          show: true,
          mensagem: error.response.data.mensagem,
          error: true,
        });
      });
  };

  useEffect(() => {
    if (limparStore) {
      dispatch(primeiroAcessoReset());
      dispatch(getLoginReset());
      setLimparStore(false);
    }
  }, [limparStore, dispatch, navigate, loginStorage]);

  const togglePassword = () => {
    passwordShown
      ? setChangeEye("bi bi-eye-slash pass-hide")
      : setChangeEye("bi bi-eye pass-hide");
    setPasswordShown(!passwordShown);
  };

  const handleLogin = (values) => {
    setLoginData(values);
  };

  function setAddAcesso(e) {
    setPasso(e);
  }
  const validationSchemaCpf = yup.object().shape({
    cpf: yup
      .string()
      .min(11, "CPF tem 11 digitos")
      .max(11, "CPF tem 11 digitos")
      .matches(/^[0-9]{11}$/, "somente números")
      .required("CPF é obrigatório"),
    recaptcha: yup.string().required(),
  });

  const validationSchemaToken = yup.object().shape({
    codigo2fa: yup.string().required("CPF é obrigatório"),
  });

  const validationSchema = yup.object().shape({
    cpf: yup
      .string()
      .min(11, "CPF tem 11 digitos")
      .max(11, "CPF tem 11 digitos")
      .matches(/^[0-9]{11}$/, "somente números")
      .required("CPF é obrigatório"),
    senha: yup
      .string()
      .min(8, "Senha deve ter pelo menos 8 caracteres")
      .matches(/^(?=.*?[A-Z])(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/, {
        message: "Senha inválida",
        excludeEmptyString: true,
      })
      .required("Senha é obrigatório"),
    recaptcha: yup.string().required(),
  });

  const voltarPasso = (e) => {
    setPasso(e);
  };

  const handleLoginPrimeiroAcesso = (values) => {
    sessionStorage.removeItem("acessoEspelho");
    dispatch(primeiroAcessoAction(values));
  };

  useEffect(() => {
    let modalTimePrimeiroAcesso;

    if (primeiroAcessoState) {
      navigate("/cadastro");
    }
    if (primeiroAcessoErrors) {
      console.log("error", primeiroAcessoErrors);
    } else {
      clearTimeout(modalTimePrimeiroAcesso);
    }
  }, [
    primeiroAcessoState,
    primeiroAcessoLoading,
    primeiroAcessoErrors,
    primeiroAcessoMessage,
    navigate,
    limparStore,
  ]);

  const handleLoginCpf = (values) => {
    setCpf(values.cpf);
    setIsLoading(true);
    api
      .get(rotasApi.obterDadosCadastrais + values.cpf, {
        headers: {
          frontmt: "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          SkipAuth: "true",
        },
      })
      .then((result) => {
        if (result.data.sucesso) {
          if (result.data.dadosCadastrais.email) {
            setEmailForm(`Email: ${result.data.dadosCadastrais.email}`);
            if (result.data.dadosCadastrais.celular) {
              setTelForm(`SMS: ${result.data.dadosCadastrais.celular}`);
            }
            let emailArray = result.data.dadosCadastrais.email.split("@");
            setEmailFinal("@" + emailArray[1]);
            setPasso(5);
          }
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalData({
          show: true,
          mensagem: error.response.data.mensagem,
          error: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LoginPage>
      <ModalAvisoRequisicao modalData={modalData} turnOffModal={turnOffModal} />
      {isLoading && <LoadingArquivo />}
      <LeftSide>
        {passo === 1 && (
          <div className="container-tomas">
            <img src={images.LoginImg} className="tom-mais-top" alt="" />{" "}
          </div>
        )}
        {passo === 2 && (
          <div className="container-tomas">
            {" "}
            <img
              src={images.LoginImgPrimeiroAcesso}
              className="tom-mais-top primeiro-acesso"
              alt=""
            />{" "}
          </div>
        )}
        {passo === 3 && (
          <div className="container-tomas">
            {" "}
            <img
              src={images.LoginImgPrimeiroAcesso}
              className="tom-mais-top primeiro-acesso"
              alt=""
            />{" "}
          </div>
        )}
        {passo === 4 && (
          <div className="container-tomas">
            <img src={images.LoginImg} className="tom-mais-top" alt="" />{" "}
          </div>
        )}
        {passo === 5 && (
          <div className="container-tomas">
            <img src={images.LoginImg} className="tom-mais-top" alt="" />{" "}
          </div>
        )}
        {passo === 6 && (
          <div className="container-tomas">
            <img src={images.LoginImg} className="tom-mais-top" alt="" />{" "}
          </div>
        )}
        {passo === 7 && (
          <div className="container-tomas">
            <img src={images.LoginImg} className="tom-mais-top" alt="" />{" "}
          </div>
        )}
        {passo === 8 && (
          <div className="container-tomas">
            <img src={images.LoginImg} className="tom-mais-top" alt="" />{" "}
          </div>
        )}
      </LeftSide>
      <RightSide>
        <CardLogin>
          {passo !== 8 ? (
            <img
              src={LogoDesk}
              alt=""
              className={
                passo === 1 ? "logo-mais-top" : "logo-mais-top passo-1"
              }
            />
          ) : (
            ""
          )}
          {passo === 1 && (
            <>
              <Card className="card-login border-0 align-items-center login-padrao">
                <Card.Body>
                  <img
                    className="enfeite-bolas-natal"
                    src={images.EnfeiteBolasNatal}
                    alt=""
                  />
                  <Card.Title className="text-left titulo-card">
                    Acessar plataforma
                  </Card.Title>
                  <Formik
                    initialValues={{
                      cpf: "",
                      senha: "",
                      recaptcha: "",
                    }}
                    onSubmit={(values) => handleLogin(values)}
                    validationSchema={validationSchema}
                  >
                    {({
                      values,
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      setFieldValue,
                      dirty,
                    }) => (
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="col-12"
                      >
                        <Form.Group className="mb-3">
                          <Form.Label className="label-input">CPF</Form.Label>

                          <Form.Control
                            type="text"
                            maxLength="11"
                            name="cpf"
                            value={values.cpf}
                            onChange={handleChange}
                            isInvalid={!!errors.cpf}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cpf}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 position-relative">
                          <Form.Label className="label-input">Senha</Form.Label>
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            name="senha"
                            value={values.senha}
                            onChange={handleChange}
                            isInvalid={!!errors.senha}
                          />
                          <i className={changeEye} onClick={togglePassword}></i>
                          <Form.Control.Feedback type="invalid">
                            {errors.senha}
                          </Form.Control.Feedback>
                          <div className="d-flex justify-content-end checkbox-senha">
                            <div>
                              <Link
                                className="link-secondary"
                                onClick={(e) => {
                                  setAddAcesso(3);
                                }}
                              >
                                Esqueceu sua senha ?
                              </Link>
                            </div>
                          </div>
                        </Form.Group>

                        <div className="d-flex justify-content-center mt-4">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={sitekey}
                            onChange={(e) => {
                              setFieldValue("recaptcha", e);
                            }}
                            // size="normal"
                          />
                        </div>
                        <div className="d-flex justify-content-center">
                          <Button
                            type="submit"
                            disabled={!isValid || !dirty}
                            variant="secondary col"
                            className="mt-4 btn-login"
                          >
                            Entrar
                          </Button>
                        </div>
                        <Card.Text className="possui-cadastro mt-md-4 mt-3">
                          Ainda não possui cadastro ?
                          <Link
                            to="#"
                            className="link-secondary"
                            onClick={(e) => {
                              setAddAcesso(2);
                            }}
                          >
                            {" "}
                            Clique aqui
                          </Link>
                        </Card.Text>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </>
          )}
          {passo === 2 && (
            <>
              <Card className="card-login border-0 align-items-center">
                <Card.Body className="width-car-primeiro">
                  <Card.Title className="text-left titulo-card primeiro">
                    Primeiro Acesso
                  </Card.Title>
                  <div className="d-flex justify-content-center mb-3"></div>

                  <Formik
                    initialValues={{ cpf: "" }}
                    onSubmit={(values) => handleLoginPrimeiroAcesso(values)}
                    validationSchema={validationSchemaCpf}
                  >
                    {({
                      values,
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      setFieldValue,
                      dirty,
                    }) => (
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="col-12"
                      >
                        <Form.Group className="mb-3">
                          <Form.Label className="label-input">CPF</Form.Label>

                          <Form.Control
                            maxLength="11"
                            type="text"
                            name="cpf"
                            value={values.cpf}
                            onChange={handleChange}
                            isInvalid={!!errors.cpf}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cpf}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-flex justify-content-center mt-4">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={sitekey}
                            onChange={(e) => {
                              setFieldValue("recaptcha", e);
                            }}
                            // size="normal"
                          />
                        </div>
                        <div className="d-flex justify-content-center">
                          <Button
                            type="submit"
                            disabled={!isValid || !dirty}
                            variant="secondary col"
                            className="mt-4 enviar-primeiro-acesso"
                          >
                            Enviar
                          </Button>
                        </div>
                        <p className="possui-cadastro mb-0 mt-5">
                          Lembrou da sua senha?
                          <Link
                            className="link-secondary"
                            to="#"
                            onClick={(e) => {
                              setAddAcesso(1);
                            }}
                          >
                            {" "}
                            Ir para o login
                          </Link>
                        </p>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </>
          )}
          {passo === 3 && (
            <>
              <button
                className="botao-voltar mt-sm-4"
                onClick={() => voltarPasso(1)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99998 13.28L5.65331 8.9333C5.13998 8.41997 5.13998 7.57997 5.65331 7.06664L9.99998 2.71997"
                    stroke="#EF4E23"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Voltar
              </button>
              <Card className="d-flex justify-content-left border-0 align-items-left bd-radius esqueceu-senha">
                <Card.Body className="body-card">
                  <Card.Title className="text-start" as="h5">
                    Recuperar senha
                  </Card.Title>
                  <Card.Text className="text-start">
                    <b>Tudo bem!</b> informe o CPF cadastrado e receba as
                    instruções necessárias.
                  </Card.Text>
                  <Formik
                    initialValues={{ cpf: "" }}
                    onSubmit={(values) => {
                      handleLoginCpf(values);
                    }}
                    validationSchema={validationSchemaCpf}
                  >
                    {({
                      values,
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      setFieldValue,
                      dirty,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-5" controlId="formBasicEmail">
                          <Form.Label>CPF</Form.Label>
                          <Form.Control
                            type="text"
                            maxLength="11"
                            name="cpf"
                            value={values.cpf}
                            onChange={handleChange}
                            isInvalid={!!errors.cpf}
                            placeholder="000.000.000-00"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cpf}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-flex justify-content-center">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={sitekey}
                            onChange={(e) => {
                              setFieldValue("recaptcha", e);
                            }}
                            // size="normal"
                          />
                        </div>
                        <Button
                          type="submit"
                          className="w-100 mt-4"
                          disabled={!isValid || !dirty}
                        >
                          Enviar
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  <Card.Text className="text-center small cadastro mt-5">
                    Lembrou da sua senha ?{" "}
                    <Link
                      className="link-secondary"
                      onClick={(e) => {
                        setAddAcesso(1);
                      }}
                    >
                      Ir para login
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </>
          )}
          {passo === 4 && (
            <>
              {/*   */}
              <Card className="d-flex justify-content-center border-0 align-items-center bd-radius enviar-codigo">
                <Card.Body className="body-card">
                  <Card.Title className="text-start" as="h5">
                    Enviar código por e-mail
                  </Card.Title>
                  <Card.Text className="text-start sub-title mb-4">
                    <p className="mb-4">
                      Você irá receber o código de autenticação no e-mail
                      cadastrado.
                    </p>
                    <p
                      className="e-mail font-weight-bold"
                      style={{ fontSize: "16px" }}
                    >
                      E-mail:{" "}
                      <span>
                        <b>{dicaEmail}</b>
                      </span>
                    </p>
                  </Card.Text>
                  <Formik
                    initialValues={{ codigo2fa: "" }}
                    onSubmit={(values) => {
                      handleSaveKey(values);
                    }}
                    // validationSchema={validationSchemaToken}
                  >
                    {({
                      values,
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      setFieldValue,
                      dirty,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-5" controlId="formBasicEmail">
                          <label htmlFor="authCode">
                            Informe o código de autenticação
                          </label>
                          <input
                            type="text"
                            id="authCode"
                            placeholder="Digite o código"
                            value={values.codigo2fa}
                            name="codigo2fa"
                            onChange={(e) => {
                              handleChangeVerif(e);
                              handleChange(e);
                            }}
                            style={{
                              display: "block",
                              marginTop: "8px",
                              padding: "8px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              height: "56px",
                              width: "100%",
                            }}
                          />
                          {error && <p style={{ color: "red" }}>{error}</p>}
                        </Form.Group>
                        <div className="tempo-reenviar d-flex">
                          <span>{formatTime()}</span>
                          <p className="ms-3 my-0">
                            {!reenviaCodigo
                              ? "Código de autenticação expirado. Por favor, solicite código novo!"
                              : "O Código de autenticação expira em breve!"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={sitekey}
                            onChange={(e) => {
                              setFieldValue("recaptcha", e);
                            }}
                            // size="normal"
                          />
                        </div>
                        <Button
                          type="submit"
                          className="w-100 verif"
                          disabled={habilitarVerificarCod}
                          onClick={() => {
                            values.reenvio = false;
                          }}
                        >
                          Verificar código
                        </Button>

                        <Button
                          type="submit"
                          className="w-100 reenviar"
                          disabled={reenviaCodigo}
                          onClick={() => {
                            values.reenvio = true;
                          }}
                        >
                          Reenviar código
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  <Card.Text className="text-center small cadastro mt-5">
                    Lembrou da sua senha ?{" "}
                    <Link
                      className="link-secondary"
                      onClick={(e) => {
                        setAddAcesso(1);
                      }}
                    >
                      Ir para o login
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </>
          )}

          {passo === 5 && (
            <>
              <button
                className="botao-voltar mb-lg-4 mt-md-4"
                onClick={() => voltarPasso(3)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99998 13.28L5.65331 8.9333C5.13998 8.41997 5.13998 7.57997 5.65331 7.06664L9.99998 2.71997"
                    stroke="#EF4E23"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Voltar
              </button>
              <Card className="d-flex justify-content-center border-0 align-items-center bd-radius escolher-envio">
                <Card.Body className="body-card">
                  <Card.Title className="text-left" as="h5">
                    Estamos quase lá.
                  </Card.Title>
                  <Card.Text className="text-left">
                    Para sua segurança vamos enviar um código de confirmação
                    escolha como prefere receber:
                  </Card.Text>
                  <Formik
                    initialValues={{ escolha: "" }}
                    className="teste2"
                    onSubmit={(values) => {
                      handleLoginEscolha(values);
                    }}
                    validationSchema={validationSchemaEscolha}
                  >
                    {({
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      dirty,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group
                          className="form-check-email"
                          controlId="formBasicEmail"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "40px",
                          }}
                        >
                          <Form.Check
                            type="radio"
                            label={
                              " " +
                              emailForm.slice(0, 10).padEnd(18, "*") +
                              emailFinal
                            }
                            onChange={handleChange}
                            value="email"
                            name="escolha"
                            id="1"
                            style={{ fontSize: "16px" }}
                          />
                          {telForm && (
                            <Form.Check
                              type="radio"
                              label={
                                " " +
                                telForm.slice(0, 9).padEnd(15, "*") +
                                "-" +
                                telForm.slice(-4)
                              }
                              onChange={handleChange}
                              value="phone"
                              name="escolha"
                              id="2"
                              style={{ fontSize: "16px" }}
                            />
                          )}

                          <Form.Control.Feedback type="invalid">
                            {errors.escolha}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                          type="submit"
                          className="w-100  btn-type-1"
                          disabled={!isValid || !dirty}
                        >
                          Enviar código
                        </Button>
                        <Button
                          className="btn-type-2 w-100"
                          onClick={(e) => {
                            setAddAcesso(1);
                          }}
                        >
                          Cancelar
                        </Button>
                        <p className="possui-cadastro mb-0 mt-5">
                          Lembrou da sua senha?
                          <Link
                            className="link-secondary"
                            to="#"
                            onClick={(e) => {
                              setAddAcesso(1);
                            }}
                          >
                            {" "}
                            Ir para o login
                          </Link>
                        </p>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </>
          )}

          {passo === 6 && (
            <>
              <button className="botao-voltar" onClick={() => voltarPasso(5)}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99998 13.28L5.65331 8.9333C5.13998 8.41997 5.13998 7.57997 5.65331 7.06664L9.99998 2.71997"
                    stroke="#EF4E23"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Voltar
              </button>
              <Card className="d-flex justify-content-center align-items-center bd-radius digita-codigo border-0">
                <Card.Body className="body-card">
                  <Card.Text className="text-left icon-size"></Card.Text>
                  <Card.Title className="text-left my-4" as="h5">
                    Estamos quase lá.
                  </Card.Title>
                  <Card.Text className="text-left">
                    Código enviado para: {/* <br /> */}
                    <span className="fw-bold">
                      {escolhaForm === "email"
                        ? emailForm
                            .slice(0, 10)
                            .padEnd(18, "*")
                            .replace("Email:", "") + emailFinal
                        : telForm
                            .slice(0, 9)
                            .padEnd(15, "*")
                            .replace("SMS:", "") +
                          "-" +
                          telForm.slice(-4)}
                    </span>
                    <br />
                    Caso não tenha recebido dentro de {"  "}
                    {formatTime()} minutos, tente novamente.
                  </Card.Text>
                  <Formik
                    initialValues={{
                      codigo: "",
                      recaptcha: "",
                    }}
                    onSubmit={(values) => handleLoginCodigo(values)}
                    validationSchema={validationSchemaCodigo}
                  >
                    {({
                      values,
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      dirty,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <p className="text-left">
                          Digite abaixo o código que você recebeu!
                        </p>
                        <Form.Group
                          className="input-codigo"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Código de segurança</Form.Label>
                          <Form.Control
                            type="text"
                            maxLength="6"
                            name="codigo"
                            value={values.codigo}
                            onChange={handleChange}
                            placeholder="Informe o código"
                          />
                        </Form.Group>
                        {/* <DigitInput>
                          <Form.Control
                            className="text-uppercase"
                            maxLength="6"
                            type="text"
                            name="codigo"
                            value={values.codigo}
                            onChange={handleChange}
                          />
                          <div className="enfeite">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </DigitInput> */}
                        <Button
                          type="submit"
                          className="w-100 btn-type-1"
                          // disabled={!isValid || !dirty}
                        >
                          Enviar
                        </Button>

                        <Button
                          className="w-100  btn-type-2"
                          disabled={reenviaCodigo}
                          // type="submit"
                          variant="secondary-outline"
                          onClick={() => {
                            handleLoginEscolhaReenviar();
                          }}
                        >
                          Reenviar código
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </>
          )}

          {passo === 7 && (
            <Card className="d-flex justify-content-center align-items-center border-0 bd-radius escreve-nova-senha">
              <Card.Body className="body-card">
                <Card.Title className="text-left" as="h5">
                  Crie uma nova senha
                </Card.Title>
                <Formik
                  initialValues={{ senha: "", confirmaSenha: "" }}
                  onSubmit={(values) => handleLoginNovaSenha(values)}
                  validationSchema={validationSchemaPassword}
                >
                  {({
                    values,
                    handleChange,
                    errors,
                    isValid,
                    handleSubmit,
                    dirty,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group className="mb-3 position-relative">
                        <Form.Label className="label-input">
                          Nova Senha
                        </Form.Label>
                        <Form.Control
                          type={passwordShown ? "text" : "password"}
                          name="senha"
                          value={values.senha}
                          onChange={handleChange}
                          isInvalid={!!errors.senha}
                        ></Form.Control>
                        <i className={changeEye} onClick={togglePassword}></i>
                        <Form.Control.Feedback type="invalid">
                          {errors.senha}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3 position-relative">
                        <Form.Label className="label-input">
                          Repita a nova senha
                        </Form.Label>
                        <Form.Control
                          type={passwordShown ? "text" : "password"}
                          name="confirmaSenha"
                          value={values.confirmaSenha}
                          onChange={handleChange}
                          isInvalid={!!errors.confirmaSenha}
                        />
                        <i className={changeEye} onClick={togglePassword}></i>
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmaSenha}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Card.Text className="small">
                        A senha precisa conter no mínimo 8 caracteres, uma letra
                        maiúscula, uma letra minúscula, um número e um caractere
                        especial. (ex.: @, !, ?, %).
                      </Card.Text>

                      <Button
                        type="submit"
                        className="w-100 mt-4 btn-type-1"
                        disabled={!isValid || !dirty}
                      >
                        Salvar
                      </Button>
                      <Button
                        className="btn btn-type-2 w-100 mt-3"
                        to="/"
                        onClick={(e) => {
                          setAddAcesso(1);
                        }}
                      >
                        Cancelar
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          )}
          {passo === 8 && (
            <Card className="d-flex justify-content-center align-items-center bd-radius border-0 mensagem-senha">
              <Card.Body className="body-card">
                <Card.Text className="text-center icon-size">
                  <img src={iconChecked} alt="" />
                </Card.Text>
                <Card.Title className="text-center" as="h5">
                  Parabéns
                </Card.Title>
                <p>Senha redefinida com sucesso!</p>
                <Button
                  className="btn w-100 mt-3 btn-type-1"
                  // to="/"
                  onClick={(e) => {
                    setPasso(1);
                  }}
                >
                  Ir para Login
                </Button>
              </Card.Body>
            </Card>
          )}
          {passo === 1 && (
            <div className="d-flex justify-content-center flex-column mt-sm-3 mt-md-2 avisos">
              <hr />
              <div className="info-telefone text-center">
                <p className="my-0 strong">Central de Atendimento +top</p>
                <h5 className="my-0">
                  0800 780 0606 - 2ª a 6ª Feira (exceto feriados)
                </h5>
                <p className="mt-4 mb-0 strong">Atendimento Telefônico</p>
                <p className="mb-0"> das 10h às 16h | WhatsApp das 9h às 18h</p>
                <p className="mt-4 email">atendimento@programamaistop.com.br</p>
              </div>
            </div>
          )}
        </CardLogin>
      </RightSide>
    </LoginPage>
  );
}
