import React, { useState, useEffect, useRef } from "react";
import { Container } from "./styles";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import {
  ContainerInfo,
  GerenciarEquipeTabela,
  Content,
  GerenciarEquipeDados,
  ContainerDados,
} from "./styles";
import EditIcon from "../../images/img/edit-icon.svg";
import NextIcon from "../../images/img/icon-arrow-pagination-next.svg";
import PreviousIcon from "../../images/img/icon-arrow-pagination-previous.svg";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
import Table from "react-bootstrap/Table";
import { Form, Pagination } from "react-bootstrap";
import { ModalAvisoGenerico } from "../../components/ModalAvisoGenerico";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export function GerenciarEquipe({ user }) {
  const [Passo, setPasso] = useState(1);
  const [date, setDate] = useState("");
  const [date2, setDate2] = useState("");
  const [equipe, setEquipe] = useState([]);
  const [filteredEquipe, setFilteredEquipe] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [infoUsuario, setinfoUsuario] = useState([]);
  const [equipeUsuario, setEquipeUsuario] = useState();
  const [statusValue, setStatusValue] = useState();
  const [descricao, setDescricao] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalAviso, setModalAviso] = useState(false);
  const [modalAvisoMensagem, setModalAvisoMensagem] = useState("");
  const [errors, setErrors] = useState({ descricao: "" });
  const [modalData, setModalData] = useState({
    show: false,
    mensagem: "",
    error: false,
  });
  const itemsPerPage = 5;
  const totalPages = Math.ceil(filteredEquipe.length / itemsPerPage);
  const maxPagesToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  const [charCount, setCharCount] = useState(0);
  const maxChars = 150;

  const handleTextChange = (e) => {
    const text = e.target.value;

    if (text.length <= maxChars) {
      setDescricao(text);
      setCharCount(text.length);
      setErrors({ descricao: "" });
    } else {
      setErrors({
        descricao: `A descrição não pode ter mais de ${maxChars} caracteres.`,
      });
    }
  };

  const fileInputRef = useRef(null);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalData({
        show: false,
        mensagem: "Selecione um arquivo para envio!",
        error: false,
      });
    }
  };

  const formatFileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / Math.pow(1024, i)).toFixed(2)} ${
      ["B", "KB", "MB", "GB", "TB"][i]
    }`;
  };

  const convertDateToDateTime = (date) => {
    const [day, month, year] = date.split("/");
    const fullYear = year.length === 2 ? `20${year}` : year;
    const formattedDate = `${fullYear}-${month}-${day}T00:00:00`;
    return formattedDate;
  };


  const EnviarSolicitacao = () => {
    const newErrors = {};

    if (!date) {
      newErrors.date = "Data inicial do afastamento é obrigatória";
    }

    // Verificar se `date2` é obrigatório apenas quando `statusValue` não é 3
    if (statusValue === 3 && !date2) {
      newErrors.date2 = "Data final do afastamento é obrigatória";
    }

    if (!descricao) {
      newErrors.descricao = "Descrição é obrigatória";
    }

    if (!selectedFile) {
      newErrors.selectedFile = "O upload de arquivo é obrigatório";
      setModalData({
        show: true,
        mensagem: "Selecione um arquivo para envio!",
        error: true,
      });
    }

    // Se houver erros, eles serão mostrados e a função terminará aqui
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Limpa os erros
    setErrors({});

    // Cria o FormData para a requisição
    const formData = new FormData();
    formData.append("UsuarioId", infoUsuario.id);
    formData.append("Motivo", descricao);
    formData.append("Anexo", selectedFile);
    formData.append("StatusUsuario", statusValue);
    formData.append("DataInicioAusencia", convertDateToDateTime(date));

    // Adiciona DataFimAusencia apenas se `statusValue` não for 3 e `date2` estiver presente
    if (statusValue === 3 && date2) {
      formData.append("DataFimAusencia", convertDateToDateTime(date2));
    }

    // Envia a requisição
    api
      .post(rotasApi.GestaoEquipeSolicitacao, formData)
      .then((result) => {
        setModalData({
          show: true,
          mensagem: result.data,
          error: false,
        });
        setPasso(1);
        setSelectedFile(null);
        setDate("");
        setDate2("");
        setDescricao("");
      })
      .catch((error) => {
        setModalAvisoMensagem(error.response.data);
        setModalAviso(true);
        setModalData({
          show: true,
          mensagem: error.response.data,
          error: true,
        });
      })
      .finally(() => {
        setModalData({ show: false });
      });
};



  const cancelar = () => {
    editar(1);
    setPasso(1);
    setSelectedFile(null);
    setDate("");
    setDate2("");
    setDescricao("");
  };

  const editar = (e, id, cargo, situacaoDescricao) => {
    setPasso(e);
    obterDadosfuncionario(id);
    setinfoUsuario({
      cargo: cargo,
      situacaoDescricao:
        situacaoDescricao === "Ativo"
          ? 1
          : situacaoDescricao === "Desligado"
          ? 2
          : situacaoDescricao === "Ausente"
          ? 3
          : "",
      id: id,
    });
  };

  const obterDadosfuncionario = (id) => {
    api
      .get(rotasApi.InfoDetalhadaEquipe + `?usuarioId=${id}`)
      .then((result) => {
        setEquipeUsuario(result.data.usuarioSituacao);
        setStatusValue(result.data.usuarioSituacao.situacao);
      })
      .catch((error) => {
        console.error("Erro ao buscar configurações do Power BI:", error);
      });
  };

  useEffect(() => {
    obterListaEquipe();
  }, []);

  const obterListaEquipe = () => {
    api
      .get(rotasApi.ListarEquipe + `?TipoDeSistemaEnum=1`)
      .then((result) => {
        setEquipe(result.data.equipe);
        setFilteredEquipe(result.data.equipe);
      })
      .catch((error) => {
        console.error("Erro ao buscar", error);
      });
  };

  const formatDate = (value) => {
    value = value.replace(/\D/g, "");
    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    if (value.length >= 5) {
      value =
        value.slice(0, 2) + "/" + value.slice(2, 4) + "/" + value.slice(4, 8);
    } else if (value.length >= 3) {
      value = value.slice(0, 2) + "/" + value.slice(2, 4);
    } else if (value.length >= 1) {
      value = value.slice(0, 2);
    }

    return value;
  };

  const handleChange = (e) => {
    const formattedDate = formatDate(e.target.value);
    setDate(formattedDate);
  };

  const handleChange2 = (e) => {
    const formattedDate = formatDate(e.target.value);
    setDate2(formattedDate);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      setFilteredEquipe(equipe);
    } else {
      const filtered = equipe.filter((participant) =>
        participant.nome.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredEquipe(filtered);
    }
    setCurrentPage(1); // Reset to first page on new search
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentItems = filteredEquipe.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const popover = (
    <Popover id="popover-basic" style={{ minWidth: "700px" }}>
      <Popover.Header as="h3">{user.cargo.descricao}</Popover.Header>
      <Popover.Body className="popover-body">
        {/* O Desafio +top é um jogo onde sua performance te leva além e traz reconhecimento para o seu desempenho nas métricas do +top.<br /><br />
                Concluindo as missões mensais, a cada 3 meses você sobe de patente e ganha uma raspadinha com prêmios surpresa, além de se tornar elegível para o ranking final da Campanha Anual.
                Todos começam o jogo na patente Explorador e podem escalar, trimestralmente, para as patentes Visionário, Realizador, podendo alcançar a patente Conquistador, mais alta do jogo, em apenas 9 meses.
                Para subir de patente é só completar as missões mensais durante 3 meses. */}
        <br />
        <br />
        {/* {user.cargo.descricao === "VENDEDOR" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).<br />
                    Produto Supertop: você precisa bater sua meta de vendas de produtos Supertops.<br />
                    Produto Participante: você precisa bater sua meta de vendas dos produtos participantes.<br />
                    Treinamentos: você precisa ser aprovado nos 2 treinamentos mensais.</span> : ('')
                } */}
        {user.cargo.descricao === "GERENTE" ? (
          <span>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </span>
        ) : (
          ""
        )}
        {/* {user.cargo.descricao === "GERENTE REGIONAL" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).<br />
                    Produto Supertop: você precisa bater a meta de vendas de produtos Supertops nas suas lojas.<br />
                    Produto Participante: você precisa bater a meta de vendas dos produtos participantes nas suas lojas.<br />
                    Treinamentos: você precisa que 80% da equipe da sua loja conclua os 2 treinamentos mensais.<br /></span> : ('')
                }
                {user.cargo.descricao === "GESTOR DA INFORMACAO" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).<br />
                    Envio de bases vendas: você precisa enviar a base de vendas até o 5º dia útil do mês.<br />
                    Envio de bases hierarquia: você precisa enviar a base de hierarquia até o 5º dia útil do mês.<br />
                    Base ativa: você precisa de 80% da equipe cadastrada ativa no programa todos os meses.<br /></span> : ('')
                } */}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Container>
        <NavBar user={user} natal={true} />
        {/* <ModalAvisoGenerico modalShow={modalAviso} mensagem={modalAvisoMensagem} error={false} />
         */}
        <ModalAvisoGenerico modalData={modalData} />
        {Passo === 1 ? (
          <GerenciarEquipeTabela>
            <Content className="container">
              <h1 className="titulo-page">Gestão de equipe</h1>

              <h1 className="titulo-page-mobile">Minha Equipe</h1>
              <ContainerInfo>
                <div className="busca">
                  <h2>
                    Gestão de equipe
                    {/* <OverlayTrigger
                                            placement="bottom-start"
                                            overlay={popover}
                                        >
                                            <i className="bi bi-exclamation-circle icon-trigger-desk" style={{
                                                position: "absolute", right: "-30px", fontSize: "16px",
                                                top: "5px"
                                            }}></i>
                                        </OverlayTrigger> */}
                  </h2>
                  <form
                    className="form-busca"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <button type="submit" className="btn-buscar">
                      <i class="bi bi-search"></i>
                    </button>
                    <input
                      type="text"
                      placeholder="Buscar participante"
                      className="input-busca"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </form>
                </div>
                <hr />
                <div className="tabela">
                  <Table hover>
                    <thead>
                      <tr>
                        <th>
                          <p>Condição</p>
                        </th>
                        <th>
                          <p>Nome</p>
                        </th>
                        <th>
                          <p>Cargo</p>
                        </th>
                        <th>
                          <p>Moderação</p>
                        </th>
                        <th>
                          <p>Ação</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map(
                        (
                          {
                            situacaoDescricao,
                            nome,
                            cargo,
                            id,
                            possuiModeracao,
                          },
                          index
                        ) => {
                          // Separar o primeiro e último nome
                          const nomes = nome.split(" ");
                          const primeiroEUltimoNome = `${nomes[0]} ${
                            nomes[nomes.length - 1]
                          }`;

                          return (
                            <tr key={index}>
                              <td>
                                <p
                                  className={
                                    situacaoDescricao === "ATIVO"
                                      ? "status atv"
                                      : situacaoDescricao === "DESLIGADO"
                                      ? "status dlgd"
                                      : situacaoDescricao === "AFASTADO"
                                      ? "status afstd"
                                      : ""
                                  }
                                >
                                  {situacaoDescricao.charAt(0).toUpperCase() +
                                    situacaoDescricao.slice(1).toLowerCase()}
                                </p>
                              </td>
                              <td>
                                <p>{primeiroEUltimoNome}</p>
                              </td>
                              <td>
                                <p>{cargo}</p>
                              </td>
                              <td>
                                <p>
                                  {possuiModeracao
                                    ? possuiModeracao
                                    : "Sem Moderação"}
                                </p>
                              </td>
                              <td>
                                <button
                                  disabled={possuiModeracao ? true : false}
                                  onClick={() => {
                                    editar(2, id, cargo, situacaoDescricao);
                                  }}
                                  className="editar-btn"
                                >
                                  <img src={EditIcon} alt="" />
                                  Editar
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <img src={PreviousIcon} alt="" />
                    </Pagination.Prev>
                    {Array.from(
                      { length: endPage - startPage + 1 },
                      (_, idx) => startPage + idx
                    ).map((number) => (
                      <Pagination.Item
                        key={number}
                        active={number === currentPage}
                        onClick={() => handlePageChange(number)}
                      >
                        {number}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      {/* {PreviousIcon} */}
                      <img src={NextIcon} alt="" />
                    </Pagination.Next>
                  </Pagination>
                </div>
              </ContainerInfo>
            </Content>
          </GerenciarEquipeTabela>
        ) : (
          <GerenciarEquipeDados>
            <div className="container title-page">
              <h1>Gestão de equipe</h1>
              <p>
                Atualize o status de participação da sua equipe justificando a
                alteração.
                <br />{" "}
                <b>
                  A confirmação da solicitação será enviada por e-mail em até 3
                  dias úteis.
                </b>
              </p>
            </div>
            <ContainerDados className="container">
              <div>
                <h3>Dados</h3>
                <div className="input-dados">
                  <div>
                    <Form.Label htmlFor="inputPassword5">Nome</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputPassword5"
                      aria-describedby="passwordHelpBlock"
                      value={equipeUsuario ? equipeUsuario.nome : ""}
                      disabled
                    />
                  </div>
                  <div>
                    <Form.Label htmlFor="inputPassword5">Cargo</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputPassword5"
                      aria-describedby="passwordHelpBlock"
                      value={infoUsuario ? infoUsuario.cargo : ""}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div>
                <h3>Situação atual</h3>
                <div className="input-situacao">
                  <div>
                    <Form.Label htmlFor="status">Status</Form.Label>
                    <Form.Select
                      size="lg"
                      id="status"
                      defaultValue={infoUsuario.situacaoDescricao}
                      style={{ fontSize: "16px" }}
                      onChange={(e) => {
                        setStatusValue(parseInt(e.target.value, 10));
                      }}
                    >
                      <option value={1}>Ativo</option>
                      <option value={2}>Desligado</option>
                      <option value={3}>Afastado</option>
                    </Form.Select>
                  </div>
                  {statusValue === 1 ||
                  statusValue === 2 ||
                  statusValue === 3 ? (
                    <div>
                      <Form.Label htmlFor="status">
                        Data inicial do afastamento
                      </Form.Label>
                      <Form.Control
                        placeholder="DD/MM/AA"
                        aria-label="DD/MM/AA"
                        aria-describedby="basic-addon2"
                        type="text"
                        id="date"
                        value={date}
                        onChange={handleChange}
                        maxLength="10"
                        isInvalid={!!errors.date}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.date}
                      </Form.Control.Feedback>
                    </div>
                  ) : (
                    <div>{statusValue}</div>
                  )}
                  {
                  statusValue === 3 ? (
                    <div>
                      <Form.Label htmlFor="status">
                        Data final do afastamento
                      </Form.Label>
                      <Form.Control
                        placeholder="DD/MM/AA"
                        aria-label="DD/MM/AA"
                        aria-describedby="basic-addon2"
                        type="text"
                        id="date2"
                        value={date2}
                        onChange={handleChange2}
                        maxLength="10"
                        isInvalid={!!errors.date2}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.date2}
                      </Form.Control.Feedback>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div style={{ marginBottom: "55px" }}>
                <h3>Descrição e upload de arquivo</h3>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={handleTextChange}
                    isInvalid={!!errors.descricao}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.descricao}
                  </Form.Control.Feedback>
                  <div style={{ textAlign: "right", marginTop: "5px" }}>
                    {charCount}/{maxChars} caracteres
                  </div>
                </Form.Group>
              </div>
              <div
                className="envio-arquivo"
                onClick={handleFileClick}
                style={{ cursor: "pointer" }}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.973 24.8133C31.333 20.9999 28.9997 13.3466 23.253 12.6266C21.1997 0.173265 3.23971 4.89326 7.49304 16.7466M9.70649 17.2932C8.99982 16.9332 8.21315 16.7465 7.42649 16.7598C1.21315 17.1998 1.22649 26.2398 7.42649 26.6798H14.9998M21.093 13.1866C21.7863 12.8399 22.533 12.6532 23.3063 12.6399"
                    stroke="#EF4E23"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.1667 27L21.1667 19M21.1667 19L18.5 21.6667M21.1667 19L23.8332 21.6671"
                    stroke="#EF4E23"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>
                  <a href="#">Clique aqui</a> para enviar seu documento
                </p>
                <span>Formatos: PDF, JPG, JPEG, PNG até 10 Mb.</span>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept=".pdf,.jpg,.jpeg,.png"
                  isInvalid={!!errors.selectedFile}
                />
                {errors.selectedFile && (
                  <div className="invalid-feedback">{errors.selectedFile}</div>
                )}
              </div>
              {selectedFile && (
                <div className="arquivo-enviado">
                  <div className="nome">
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="48"
                        height="48"
                        rx="8"
                        fill="#B1D667"
                        fillOpacity="0.2"
                      />
                      <path
                        d="M20.0003 37.3337H28.0003C34.667 37.3337 37.3337 34.667 37.3337 28.0003V20.0003C37.3337 13.3337 34.667 10.667 28.0003 10.667H20.0003C13.3337 10.667 10.667 13.3337 10.667 20.0003V28.0003C10.667 34.667 13.3337 37.3337 20.0003 37.3337Z"
                        stroke="#7EA72D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.9997 21.3333C21.4724 21.3333 22.6663 20.1394 22.6663 18.6667C22.6663 17.1939 21.4724 16 19.9997 16C18.5269 16 17.333 17.1939 17.333 18.6667C17.333 20.1394 18.5269 21.3333 19.9997 21.3333Z"
                        stroke="#7EA72D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5596 33.2671L18.1329 28.8537C19.1862 28.1471 20.7062 28.2271 21.6529 29.0404L22.0929 29.4271C23.1329 30.3204 24.8129 30.3204 25.8529 29.4271L31.3996 24.6671C32.4396 23.7737 34.1196 23.7737 35.1596 24.6671L37.3329 26.5337"
                        stroke="#7EA72D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="detalhes-arquivo">
                      <span className="nome-arquivo">{selectedFile.name}</span>
                      <span className="tamanho-arquivo">
                        {formatFileSize(selectedFile.size)}
                      </span>
                    </div>
                  </div>
                  <span
                    className="close-icon"
                    onClick={() => setSelectedFile(null)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.5 4.5L19.4995 19.4995M4.5 19.4995L19.4995 4.5"
                        stroke="#15151F"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              )}
              <div className="botoes-acao">
                <div className="container-btn">
                  <button className="enviar" onClick={EnviarSolicitacao}>
                    Enviar solicitação
                  </button>
                  <button
                    className="cancelar"
                    onClick={() => {
                      cancelar();
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </ContainerDados>
          </GerenciarEquipeDados>
        )}
        <Footer className="m-0 p-0"></Footer>
      </Container>
    </>
  );
}
